import { useMemo } from 'react';

import { JSONProject } from '../../../common/types';
import { getProjectComponentCount } from 'src/utils';
import { ComponentData } from '../types';

export default function useComponentCountSort({
  componentData,
  repos,
}: {
  componentData?: ComponentData[];
  repos: JSONProject[];
}) {
  return useMemo(() => {
    return repos.map((repo) => {
      const instances = componentData
        ?.map((component) => ({
          ...component,
          instances: component.instances.filter(
            (inst) => inst.project?.name === repo.name
          ),
        }))
        .filter(({ instances }) => instances.length);
      return {
        url: repo.url,
        componentCount: getProjectComponentCount(instances),
      };
    });
  }, [componentData, repos]);
}
