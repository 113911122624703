import React, { useContext, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonGroup } from '@adsk/alloy-react-button';
import TrackerContext from '../../TrackerContext';
import {
  SortButton,
  Sort,
  SORT_FILTERS,
  cycleSort,
} from 'src/components/SortButton';
import { SortFilter } from 'src/components/SortButton/consts';
import ProjectsAccordion from './ProjectsAccordion';
import { useControlled } from '@adsk/alloy-react-helpers';
import { DefaultItem } from '@adsk/alloy-react-dropdown';
import ProjectsMultiAutocomplete from './ProjectsMultiAutocomplete';
import ProgressRing from '@adsk/alloy-react-progress-ring';

const StyledTitle = styled.div`
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
`;

const Projects = () => {
  const context = useContext(TrackerContext);

  const [sortFilter, setSortFilter] = useState<SortFilter | undefined>(
    SORT_FILTERS.OUTDATED
  );
  const [sortOrder, setSortOrder] = useState<Sort | undefined>(Sort.desc);
  const [selectedLibraries, setSelectedLibraries] = useControlled<
    DefaultItem[] | undefined
  >([]);

  const resetSort = () => setSortOrder(undefined);

  return (
    <div style={{ marginTop: 32 }}>
      <>
        <div
          style={{
            marginBottom: 18,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ButtonGroup<SortFilter> onChange={setSortFilter} value={sortFilter}>
            <SortButton
              sortOrder={
                sortFilter === SORT_FILTERS.ALPHA ? sortOrder : undefined
              }
              value={SORT_FILTERS.ALPHA}
              defaultLabel="A-Z"
              toggleLabel="Z-A"
              onClick={() => setSortOrder(cycleSort(sortOrder))}
            />
            <SortButton
              sortOrder={
                sortFilter === SORT_FILTERS.OUTDATED ? sortOrder : undefined
              }
              defaultLabel="Most updated"
              toggleLabel="Least updated"
              value={SORT_FILTERS.OUTDATED}
              onClick={() => {
                resetSort();
                setSortOrder(cycleSort(sortOrder));
              }}
            />
            <SortButton
              sortOrder={
                sortFilter === SORT_FILTERS.COUNT ? sortOrder : undefined
              }
              defaultLabel="Component count"
              value={SORT_FILTERS.COUNT}
              onClick={() => {
                resetSort();
                setSortOrder(cycleSort(sortOrder));
              }}
            />
            <SortButton
              sortOrder={
                sortFilter === SORT_FILTERS.TYPESCRIPT ? sortOrder : undefined
              }
              defaultLabel="Typescript version"
              value={SORT_FILTERS.TYPESCRIPT}
              onClick={() => {
                resetSort();
                setSortOrder(cycleSort(sortOrder));
              }}
            />
          </ButtonGroup>
          <Suspense fallback={<ProgressRing style={{ marginLeft: 'auto' }} />}>
            <ProjectsMultiAutocomplete
              data={context.data}
              selectedItems={selectedLibraries}
              onSelectedItemsChange={({ selectedItems }) =>
                setSelectedLibraries(selectedItems || [])
              }
              placeholder={'Filter repos by library...'}
              containerStyles={{
                width: '32%',
                marginLeft: 'auto',
                marginBottom: '0',
              }}
            />
          </Suspense>
        </div>
        <StyledTitle>Libraries</StyledTitle>
        {context.data && context.allWebComponents && (
          <Suspense
            fallback={
              <div
                style={{
                  height: '30vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ProgressRing size={ProgressRing.SIZES.LARGE} />
              </div>
            }
          >
            <ProjectsAccordion
              projectDataResource={context.data}
              allComponentResource={context.allWebComponents}
              projectType="library"
              sortFilter={sortFilter}
              sortOrder={sortOrder}
              selectedLibraries={selectedLibraries}
            />
          </Suspense>
        )}
        <StyledTitle style={{ marginTop: 10 }}>Features</StyledTitle>
        {context.data && context.allWebComponents && (
          <Suspense
            fallback={
              <div
                style={{
                  height: '30vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ProgressRing size={ProgressRing.SIZES.LARGE} />
              </div>
            }
          >
            <ProjectsAccordion
              projectDataResource={context.data}
              allComponentResource={context.allWebComponents}
              projectType="app"
              sortFilter={sortFilter}
              sortOrder={sortOrder}
              selectedLibraries={selectedLibraries}
            />
          </Suspense>
        )}
      </>
    </div>
  );
};

Projects.propTypes = {
  data: PropTypes.object,
  usageData: PropTypes.object,
  latestAlloyVersion: PropTypes.string,
};

export default Projects;
