export const sizes = [
  {
    name: 'acc-as-built-components',
    url: 'https://git.autodesk.com/plangrid/acc-as-built-components',
    size: 1614807,
  },
  {
    name: 'acc-bridge-frontend',
    url: 'https://git.autodesk.com/plangrid/acc-bridge-frontend',
    size: 1310720,
  },
  {
    name: 'acc-bridge-search',
    url: 'https://git.autodesk.com/plangrid/acc-bridge-search',
    size: 433889,
  },
  {
    name: 'acc-handover',
    url: 'https://git.autodesk.com/plangrid/acc-handover',
    size: 1436549,
  },
  {
    name: 'acc-issues-web-lib',
    url: 'https://git.autodesk.com/BIM360/acc-issues-web-lib',
    size: 3145728,
  },
  {
    name: 'acc-project-list',
    url: 'https://git.autodesk.com/BIM360/acc-project-list',
    size: 1677721,
  },
  {
    name: 'acc-rfis-web-lib',
    url: 'https://git.autodesk.com/BIM360/acc-rfis-web-lib',
    size: 248555,
  },
  {
    name: 'acc-sitevision-web-lib',
    url: 'https://git.autodesk.com/BIM360/acc-sitevision-web-lib',
    size: 43724,
  },
  {
    name: 'acc-viewer',
    url: 'https://git.autodesk.com/plangrid/acc-viewer',
    size: 6186598,
  },
  {
    name: 'acc-viewer-ui',
    url: 'https://git.autodesk.com/BIM360/acc-viewer-ui',
    size: 415918,
  },
  {
    name: 'acs-admin-client',
    url: 'https://git.autodesk.com/BIM360/acs-admin-client',
    size: null,
  },
  {
    name: 'acs-admin-ui-components',
    url: 'https://git.autodesk.com/BIM360/acs-admin-ui-components',
    size: 399595,
  },
  {
    name: 'acs-apps-ui-web',
    url: 'https://git.autodesk.com/BIM360/acs-apps-ui-web',
    size: 615608,
  },
  {
    name: 'acs-assets',
    url: 'https://git.autodesk.com/BIM360/acs-assets',
    size: null,
  },
  {
    name: 'acs-assets',
    url: 'https://git.autodesk.com/plangrid/acs-assets',
    size: null,
  },
  {
    name: 'acs-b4c-revit-integrations',
    url: 'https://git.autodesk.com/BIM360/acs-b4c-revit-integrations',
    size: 4760535,
  },
  {
    name: 'acs-correspondence',
    url: 'https://git.autodesk.com/BIM360/acs-correspondence',
    size: 2149580,
  },
  {
    name: 'acs-docs',
    url: 'https://git.autodesk.com/BIM360/acs-docs',
    size: 9028239,
  },
  {
    name: 'acs-docs-share-link',
    url: 'https://git.autodesk.com/BIM360/acs-docs-share-link',
    size: 1029928,
  },
  {
    name: 'acs-exo-skeleton',
    url: 'https://git.autodesk.com/BIM360/acs-exo-skeleton',
    size: 437606,
  },
  {
    name: 'acs-locations',
    url: 'https://git.autodesk.com/BIM360/acs-locations',
    size: 3460300,
  },
  {
    name: 'acs-meetings',
    url: 'https://git.autodesk.com/BIM360/acs-meetings',
    size: 3565158,
  },
  {
    name: 'acs-notifications',
    url: 'https://git.autodesk.com/BIM360/acs-notifications',
    size: 1740636,
  },
  {
    name: 'acs-photos',
    url: 'https://git.autodesk.com/BIM360/acs-photos',
    size: 4162846,
  },
  {
    name: 'acs-projects',
    url: 'https://git.autodesk.com/BIM360/acs-projects',
    size: 1237319,
  },
  {
    name: 'acs-rfis',
    url: 'https://git.autodesk.com/BIM360/acs-rfis',
    size: 6134169,
  },
  {
    name: 'acs-schedule',
    url: 'https://git.autodesk.com/BIM360/acs-schedule',
    size: 7567,
  },
  {
    name: 'acs-submittals',
    url: 'https://git.autodesk.com/BIM360/acs-submittals',
    size: null,
  },
  {
    name: 'acs-user-home',
    url: 'https://git.autodesk.com/BIM360/acs-user-home',
    size: 1384120,
  },
  {
    name: 'autodesk-model-diff',
    url: 'https://git.autodesk.com/BIM360/autodesk-model-diff',
    size: 428769,
  },
  {
    name: 'bigboy-frontend',
    url: 'https://git.autodesk.com/plangrid/bigboy-frontend',
    size: 337008,
  },
  {
    name: 'bim360-navisworks-issues-ui',
    url: 'https://git.autodesk.com/BIM360/bim360-navisworks-issues-ui',
    size: 5714739,
  },
  {
    name: 'BIM360Dashboard',
    url: 'https://git.autodesk.com/BIM360/BIM360Dashboard',
    size: 729047,
  },
  {
    name: 'BIM360IQ',
    url: 'https://git.autodesk.com/BIM360/BIM360IQ',
    size: 5463080,
  },
  {
    name: 'components-frontend',
    url: 'https://git.autodesk.com/plangrid/components-frontend',
    size: 473415,
  },
  {
    name: 'component-library-components',
    url: 'https://git.autodesk.com/plangrid/component-library-components',
    size: 602746,
  },
  {
    name: 'cost-ui-web',
    url: 'https://git.autodesk.com/BIM360/cost-ui-web',
    size: 7780433,
  },
  {
    name: 'design-collaboration',
    url: 'https://git.autodesk.com/fluent/design-collaboration',
    size: 9583984,
  },
  {
    name: 'ecclesia-ui-web',
    url: 'https://git.autodesk.com/BIM360/ecclesia-ui-web',
    size: 4718592,
  },
  {
    name: 'exports-frontend',
    url: 'https://git.autodesk.com/plangrid/exports-frontend',
    size: 783953,
  },
  {
    name: 'issues-ui',
    url: 'https://git.autodesk.com/BIM360/issues-ui',
    size: 744560,
  },
  {
    name: 'locations-components',
    url: 'https://git.autodesk.com/BIM360/locations-components',
    size: 21913,
  },
  {
    name: 'meetings-ui-web',
    url: 'https://git.autodesk.com/BIM360/meetings-ui-web',
    size: null,
  },
  {
    name: 'model-coordination',
    url: 'https://git.autodesk.com/BIM360/model-coordination',
    size: 24798821,
  },
  {
    name: 'models-frontend',
    url: 'https://git.autodesk.com/plangrid/models-frontend',
    size: null,
  },
  {
    name: 'new-user-frontend',
    url: 'https://git.autodesk.com/plangrid/new-user-frontend',
    size: 102461,
  },
  {
    name: 'process-files-frontend',
    url: 'https://git.autodesk.com/plangrid/process-files-frontend',
    size: 2789212,
  },
  {
    name: 'quality-ui-web',
    url: 'https://git.autodesk.com/BIM360/quality-ui-web',
    size: 6039797,
  },
  {
    name: 'quantification-ui-web',
    url: 'https://git.autodesk.com/BIM360/quantification-ui-web',
    size: 6459228,
  },
  {
    name: 'reports-ui',
    url: 'https://git.autodesk.com/BIM360/reports-ui',
    size: 1499463,
  },
  {
    name: 'reports-ui-components',
    url: 'https://git.autodesk.com/BIM360/reports-ui-components',
    size: 69754,
  },
  {
    name: 'reports-ui-web',
    url: 'https://git.autodesk.com/BIM360/reports-ui-web',
    size: 7172259,
  },
  {
    name: 'team-page',
    url: 'https://git.autodesk.com/plangrid/team-page',
    size: 2380267,
  },
  {
    name: 'template-admin-frontend',
    url: 'https://git.autodesk.com/plangrid/template-admin-frontend',
    size: 482365,
  },
  {
    name: 'template-settings-frontend',
    url: 'https://git.autodesk.com/plangrid/template-settings-frontend',
    size: 567889,
  },
  {
    name: 'templates-e2e-tests',
    url: 'https://git.autodesk.com/plangrid/templates-e2e-tests',
    size: null,
  },
  {
    name: 'wildcard',
    url: 'https://git.autodesk.com/BIM360/wildcard',
    size: 34447,
  },
  {
    name: 'work-plan-ui-web',
    url: 'https://git.autodesk.com/BIM360/work-plan-ui-web',
    size: 3051356,
  },
];
