// eslint-disable-next-line
// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ScrollDiv } from './StyledScrollbar';
import * as d3 from 'd3';

const maxWidth = 1200;
const defaultWidth = 985;
const defaultHeight = 200;
const toolTipHeight = 30;
const yOffset = toolTipHeight + 15;
let width = defaultWidth;
const margin = 100;

const minWidth = 1;
// Q5 of Autodesk Color Spec
const colors = [
  '#5178C8',
  '#3BD23B',
  '#D46BC3',
  '#3BBABA',
  '#CE5C95',
  '#90D847',
  '#CE6565',
  '#E1E154',
  '#3BC580',
  '#E0AF4B',
  '#689ED4',
  '#9C6BCE',
];

const StackedBarChart = ({ allComponents, selectedItems }) => {
  const svgRef = useRef();
  const color = d3.scaleOrdinal().range(colors);

  const setSize = () => {
    const currentWidth = window.visualViewport?.width || window.innerWidth - 15;
    width = Math.min(
      Math.max(currentWidth - 2 * margin, defaultWidth),
      maxWidth
    );
  };

  const drawChart = () => {
    let totalComponentUse = 0;
    const zeroInstanceComponents = [];
    const componentData = [];

    allComponents.forEach((el) => {
      if (el.instances.length > 0) {
        totalComponentUse += el.instances.length;
        componentData.push({
          component: el.name,
          value: el.instances.length,
          width: minWidth,
          x0: 0,
          selected:
            selectedItems &&
            selectedItems.some((item) => item.label === el.name),
        });
      } else {
        zeroInstanceComponents.push(el.name);
      }
    });

    const unitWidth =
      (width - componentData.length * minWidth) / totalComponentUse;
    let acc = 0;
    componentData.forEach((el) => {
      el.width += el.value * unitWidth;
      el.x0 = acc;
      acc += el.width;
    });

    const selectedComponents = selectedItems?.filter(
      (el) => !zeroInstanceComponents.some((item) => el.label === item)
    );

    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('class', 'StackedBarChart')
      .attr('width', width)
      .attr('height', defaultHeight)
      .append('g');

    const tooltip = svg.append('g').attr('opacity', 0).attr('id', 'tooltip');

    tooltip
      .append('rect')
      .attr('id', 'toolrect')
      .attr('height', toolTipHeight)
      .attr('y', 0)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('fill', 'white');

    const triangle = d3.symbol().type(d3.symbolTriangle).size(90);
    tooltip
      .append('path')
      .attr('id', 'triangle')
      .attr('d', triangle)
      .attr('fill', 'white');

    const text = tooltip
      .append('text')
      .attr('id', 'tooltext')
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'central')
      .text('adfasfasdf')
      .attr('fill', 'black')
      .attr('x', 20)
      .attr('y', toolTipHeight / 2)
      .attr('dy', '0em');

    const drawToolTip = (d) => {
      const pageWidth = svgRef.current.offsetWidth;
      const padding =
        (d.path && d.path[3]?.offsetLeft) ||
        (window.innerWidth > 1000 ? 100 : 40);

      const toolTipPadding = 10;
      const componentX0 = d.srcElement.__data__.x0;
      const componentWidth = d.srcElement.__data__.width;
      const leftBound = d.offsetX - d.pageX + padding;

      d3.select('#tooltext')
        .text(
          `${d.srcElement.__data__.component}: ${d.srcElement.__data__.value}`
        )
        .attr('x', componentX0 + componentWidth / 2);
      const textWidth = text.node().getBBox().width + toolTipPadding;
      let rectX = componentX0 + (componentWidth - textWidth) / 2;
      let triangleX = componentX0 + componentWidth / 2;

      // prevent tooltip cutoff at ends
      if (
        componentX0 + (componentWidth + textWidth) / 2 >
        leftBound + pageWidth
      ) {
        d3.select('#tooltext').attr('x', leftBound + pageWidth - textWidth / 2);
        rectX = leftBound + pageWidth - textWidth;
        if (triangleX > leftBound + pageWidth) {
          triangleX = leftBound + pageWidth;
        }
      } else if (componentX0 + (componentWidth - textWidth) / 2 < leftBound) {
        d3.select('#tooltext').attr('x', leftBound + textWidth / 2 - 2);
        rectX = leftBound - 2;
        if (triangleX < leftBound) {
          triangleX = leftBound;
        }
      }

      d3.select('#toolrect').attr('x', rectX).attr('width', textWidth);

      d3.select('#triangle').attr(
        'transform',
        `translate(${triangleX}, ${toolTipHeight}) rotate(180)`
      );

      tooltip.transition().duration(200).attr('opacity', 1);
    };

    const removeToolTip = () => {
      tooltip.transition().duration(200).attr('opacity', 0);
    };

    const removePeriods = (name) => {
      return name.replace(/\./g, '');
    };

    svg
      .selectAll('bars')
      .data(componentData)
      .enter()
      .append('rect')
      .attr('class', 'rect-stacked')
      .attr('id', (d) => removePeriods(d.component))
      .attr('x', (d) => d.x0)
      .attr('y', yOffset)
      .attr('height', defaultHeight - yOffset)
      .attr('width', (d) => d.width)
      .on('mouseover', (d) => drawToolTip(d))
      .on('mouseleave', () => removeToolTip())
      .attr('fill', (d) =>
        selectedComponents && selectedComponents.length > 0 && !d.selected
          ? d3.rgb(color(d.component)).darker(2)
          : color(d.component)
      );

    if (selectedComponents) {
      for (const el of selectedComponents) {
        d3.select(`#${removePeriods(el.label)}`)
          .raise()
          .attr('stroke', 'white')
          .attr('stroke-width', 2);
      }
    }
  };

  useEffect(() => {
    d3.select('.StackedBarChart').remove();
    setSize();
    drawChart();
  });

  let resizeTimer;
  window.onresize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      setSize();
      d3.select('.StackedBarChart').remove();
      drawChart();
    }, 100);
  };

  return (
    <div>
      <ScrollDiv>
        <div ref={svgRef}></div>
      </ScrollDiv>
    </div>
  );
};

StackedBarChart.propTypes = {
  /** All components within alloy-react */
  allComponents: PropTypes.array.isRequired,
  /** Items selected in the Component Dropdown */
  selectedItems: PropTypes.array,
};

export default StackedBarChart;
