import React, { useCallback, Suspense } from 'react';
import Accordion, { AccordionItem } from '@adsk/alloy-react-accordion';
import theme from '@adsk/alloy-react-theme';
import GithubLink from '../../components/IconLinks/GithubLink';
import SlackLink from '../../components/IconLinks/SlackLink';
import JiraLink from '../../components/IconLinks/JiraLink';
import { IconButton, LinkButton } from '@adsk/alloy-react-button';
import { AlertWarningFilledIcon, ChartBarIcon } from '@adsk/alloy-react-icon';
import ComponentCountBadge from '../../components/ComponentCountBadge';
import Tooltip from '@adsk/alloy-react-tooltip';
import Badge from '@adsk/alloy-react-badge';
import ProjectVersionDiffCounts from '../../components/ProjectVersionDiffCounts';
import ExternalLink from '../../components/ExternalLink';
import {
  NO_VERSION,
  getBundleSize,
  getJiraUrlForBoard,
  getSlackUrlForChannel,
} from '../../utils';
import ProjectPackageVersions from '../../components/ProjectPackageVersions';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import {
  JSONProject,
  ProjectData,
  ProjectPackage,
} from '../../../../common/types';
import useComponentCountSort from 'src/hooks/useComponentCountSort';
import {
  SORT_FILTERS,
  Sort,
  SortFilter,
} from 'src/components/SortButton/consts';
import semver from 'semver';
import { DefaultItem } from '@adsk/alloy-react-dropdown';
import { ComponentData, SuspendableFetch } from 'src/types';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import HideContentsAccordionItem from '../../components/HideContentsAccordionItem';

const StyledBadge = styled(Badge)`
  color: white;
  margin-right: 10px;
`;

type ProjectItemsProps = {
  projectDataResource: SuspendableFetch<ProjectData>;
  allComponentResource: SuspendableFetch<ComponentData[]>;
  projectType: 'library' | 'app';
  sortFilter: SortFilter | undefined;
  sortOrder: Sort | undefined;
  selectedLibraries: DefaultItem[] | undefined;
};

export const accordionItemStyle = {
  backgroundColor: theme.colors.charcoal900,
  '> svg': {
    color: theme.colors.white,
    '&:hover': {
      color: theme.colors.charcoal200,
    },
  },
};

const ProjectsAccordion = ({
  projectDataResource,
  allComponentResource,
  projectType,
  sortFilter,
  sortOrder,
  selectedLibraries,
}: ProjectItemsProps) => {
  const navigate = useNavigate();

  const projectData = projectDataResource?.read();
  const componentData = allComponentResource?.read();

  const componentsCountByProject = useComponentCountSort({
    componentData,
    repos: projectData?.repos ?? [],
  });

  const sort = useCallback(
    (projectRepos?: JSONProject[]) => {
      const getCount = (projectUrl: string) =>
        componentsCountByProject.find((p) => p.url === projectUrl)
          ?.componentCount || 0;

      const arr = [...(projectRepos || [])];
      switch (sortFilter) {
        case SORT_FILTERS.COUNT:
          if (sortOrder === Sort.asc) {
            arr.sort((a, b) => getCount(b.url) - getCount(a.url));
          }
          if (sortOrder === Sort.desc) {
            arr.sort((a, b) => getCount(a.url) - getCount(b.url));
          }
          break;
        case SORT_FILTERS.OUTDATED:
          if (sortOrder === Sort.asc) {
            arr.reverse();
          }
          break;
        case SORT_FILTERS.TYPESCRIPT:
          if (sortOrder === Sort.asc) {
            arr.sort((a, b) =>
              semver.compare(
                b.tsVersion || NO_VERSION,
                a.tsVersion || NO_VERSION
              )
            );
          }
          if (sortOrder === Sort.desc) {
            arr.sort((a, b) =>
              semver.compare(
                a.tsVersion || NO_VERSION,
                b.tsVersion || NO_VERSION
              )
            );
          }
          break;
        case SORT_FILTERS.ALPHA:
        default:
          if (sortOrder === Sort.asc) {
            arr.sort((a, b) => a.name.localeCompare(b.name));
          }
          if (sortOrder === Sort.desc) {
            arr.sort((a, b) => b.name.localeCompare(a.name));
          }
          break;
      }
      return arr;
    },
    [sortFilter, sortOrder, componentsCountByProject]
  );

  const filterRepos = useCallback(
    (isLibraryContext: boolean) => {
      const libraryTypeDelegate = isLibraryContext
        ? (repo: JSONProject) => repo.isLibrary
        : (repo: JSONProject) => !repo.isLibrary;

      if (selectedLibraries && selectedLibraries.length) {
        return sort(
          projectData?.repos?.filter((repo) => {
            const shouldShow =
              libraryTypeDelegate(repo) &&
              repo.packages.some((pkg: ProjectPackage) =>
                selectedLibraries?.map((lib) => lib.value).includes(pkg.name)
              );

            if (shouldShow) {
              // backup original list of packages so we can filter for other components
              // this logic could be delegated to ProjectsAccordion or ProjectPackageVersions
              // if filtering gets more complex, but keeping it here for now so all filtering
              // and sorting is in one place
              if (!repo.allPackages) {
                repo.allPackages = repo.packages;
              }
              repo.packages = repo.allPackages.filter((pkg) =>
                selectedLibraries?.map((lib) => lib.value).includes(pkg.name)
              );
            }
            return shouldShow;
          })
        );
      } else {
        return sort(
          projectData?.repos?.filter((repo) => {
            const shouldShow = libraryTypeDelegate(repo);

            // restore packages to original state
            if (repo.allPackages) {
              repo.packages = repo.allPackages;
              repo.allPackages = undefined;
            }
            return shouldShow;
          })
        );
      }
    },
    [sort, projectData, selectedLibraries]
  );

  const repos = filterRepos(projectType === 'library');

  return (
    <Accordion
      initialExpanded={[Number.MAX_SAFE_INTEGER]}
      renderItem={HideContentsAccordionItem}
    >
      {repos.map((project) => (
        <AccordionItem
          key={project.url}
          style={accordionItemStyle}
          title={
            <div style={{ display: 'flex', alignItems: 'center', height: 36 }}>
              <GithubLink url={project.url} style={{ marginRight: 6 }} />
              <SlackLink
                project={project}
                style={{ marginRight: 6 }}
                fillColor={theme.colors.white}
              />
              {project.jiraCodes && (
                <JiraLink
                  style={{ marginRight: 6 }}
                  code={project.jiraCodes[0]}
                />
              )}
              <IconButton
                size={16}
                style={{ marginRight: 6 }}
                renderIcon={(p) => <ChartBarIcon {...p} />}
                onClick={() => navigate(`/projects/${project.name}`)}
              />
              <Suspense fallback={<ProgressRing />}>
                <ComponentCountBadge projectName={project.name} />
              </Suspense>
              <LinkButton
                style={{ color: theme.colors.white }}
                onClick={() => navigate(`/projects/${project.name}`)}
              >
                {project.name}
              </LinkButton>
            </div>
          }
          label={
            <div style={{ display: 'flex' }}>
              {project.tsVersion && (
                <Tooltip
                  variant={Tooltip.VARIANTS.LIGHT}
                  content="TypeScript version"
                >
                  <StyledBadge variant={Badge.VARIANTS.OUTLINE}>
                    {project.tsVersion}
                  </StyledBadge>
                </Tooltip>
              )}
              {projectDataResource && (
                <ProjectVersionDiffCounts
                  projectDataResource={projectDataResource}
                  projectId={project.name} // TODO: is the name the same as the projectId?
                />
              )}
            </div>
          }
        >
          <div
            style={{
              padding: '0px 24px 12px 24px',
              display: 'flex',
              flexFlow: 'column nowrap',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
              }}
            >
              {project.slackChannels && (
                <ExternalLink
                  style={{
                    marginRight: '8px',
                  }}
                  href={getSlackUrlForChannel(project?.slackChannels?.[0])}
                >
                  {project.slackChannels?.[0]}
                </ExternalLink>
              )}
              <span>|</span>
              {project.jiraCodes && (
                <ExternalLink
                  style={{
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                  href={getJiraUrlForBoard(project.jiraCodes[0])}
                >
                  {project.jiraCodes[0].split(':')[1]}
                </ExternalLink>
              )}
              {getBundleSize(project.url) && (
                <>
                  <span>|</span>
                  <div
                    style={{
                      display: 'inline-flex',
                      marginLeft: '8px',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '4px',
                      }}
                    >
                      {getBundleSize(project.url)}
                    </span>
                    <Tooltip
                      content={
                        "This measure is an initial value, please reach out to the design engineering team if you'd like to get it updated"
                      }
                      variant={Tooltip.VARIANTS.LIGHT}
                    >
                      <AlertWarningFilledIcon size={16} />
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          </div>
          {projectDataResource && (
            <ProjectPackageVersions
              projectDataResource={projectDataResource}
              projectId={project.name} // TODO: is the name the same as the projectId?
            />
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ProjectsAccordion;
