import { createContext } from 'react';
import { ProjectData } from '../../common/types';
import { UsageData, Scan, SuspendableFetch, ComponentData } from './types';

export type TrackerContextType = {
  data: SuspendableFetch<ProjectData> | undefined;
  usageData: SuspendableFetch<UsageData> | undefined;
  latestScan: SuspendableFetch<Scan> | undefined;
  mobileUsageData: SuspendableFetch<UsageData> | undefined;
  latestAndroidScan: SuspendableFetch<Scan> | undefined;
  latestIosScan: SuspendableFetch<Scan> | undefined;
  allWebComponents: SuspendableFetch<ComponentData[]> | undefined;
  allAndroidComponents: SuspendableFetch<ComponentData[]> | undefined;
  allIosComponents: SuspendableFetch<ComponentData[]> | undefined;
};

const TrackerContext = createContext<TrackerContextType>({
  data: undefined,
  usageData: undefined,
  latestScan: undefined,
  mobileUsageData: undefined,
  latestAndroidScan: undefined,
  latestIosScan: undefined,
  allWebComponents: undefined,
  allAndroidComponents: undefined,
  allIosComponents: undefined,
});

export default TrackerContext;
