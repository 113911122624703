import React, { ComponentProps } from 'react';

import { AccordionItem } from '@adsk/alloy-react-accordion';

const HideContentsAccordionItem = (
  props: ComponentProps<typeof AccordionItem>
) => {
  const { children, ...rest } = props;

  return <AccordionItem {...rest}>{rest.expanded && children}</AccordionItem>;
};

export default HideContentsAccordionItem;
