import { useMemo } from 'react';
import { isCustomizationProp } from 'src/pages/Customizations/helpers';
import { ComponentData, OverrideInstance } from 'src/types';
import { getAllPropsForComponent, getGithubUrlForInstance } from 'src/utils';

export default ({
  allComponents,
}: {
  allComponents?: ComponentData[];
}): OverrideInstance[] => {
  const componentsOverrideInstances = useMemo(
    () =>
      allComponents?.reduce((result: OverrideInstance[], component) => {
        const customizationProps = getAllPropsForComponent(component).filter(
          (prop) => isCustomizationProp(prop.name)
        );
        const overrideInstances: OverrideInstance[] = component.instances
          .filter((instance) =>
            customizationProps.some((prop) =>
              Object.keys(instance.props).includes(prop.name)
            )
          )
          .map((instance) => {
            const instanceUrl = instance.project
              ? getGithubUrlForInstance(instance, instance.project)
              : undefined;
            const filename = instance.location.file.substring(
              instance.location.file.lastIndexOf('/') + 1
            );
            const lineNumber = instance.location.start.line;
            return {
              project: instance.project?.name || 'Not mapped',
              projectUrl: instance.project?.url,
              props: instance.props,
              pkg: instance.pkg,
              name: component.name,
              instanceUrl: instanceUrl || instance.location.file,
              filename,
              lineNumber,
            };
          });
        if (overrideInstances.length) {
          result.push(...overrideInstances);
        }
        return result;
      }, []),
    [allComponents]
  );

  return componentsOverrideInstances || [];
};
