import React, { ComponentProps } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@adsk/alloy-react-button';
import { LogoJiraIcon } from '@adsk/alloy-react-icon';

import { getJiraUrlForBoard } from 'src/utils';

const JiraLink = (
  props: ComponentProps<typeof IconButton> & { code: string }
) => (
  <IconButton
    size={16}
    renderIcon={(p) => <LogoJiraIcon {...p} />}
    onClick={() => window.open(getJiraUrlForBoard(props.code), '_blank')}
    {...props}
  />
);

JiraLink.propTypes = {
  code: PropTypes.string.isRequired,
};

export default JiraLink;
