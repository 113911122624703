import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import theme from '@adsk/alloy-react-theme';

import {
  useMultiAutocomplete,
  MultiAutocompleteControl,
  MultiSelectMenu,
  DefaultItem,
  UseMultiAutocompleteProps,
} from '@adsk/alloy-react-dropdown';
import Footer, { ClearButton } from '@adsk/alloy-react-footer';

type TrackerMultiAutocompleteProps = UseMultiAutocompleteProps<DefaultItem> & {
  placeholder?: string;
  containerStyles?: React.CSSProperties;
  renderFooter?: () => ReactNode | undefined;
};

const menuItemStyle: {
  background: string;
  '&:hover'?: { background: string };
} = {
  background: '#3c3c3c',
  '&:hover': {
    background: '#666666',
  },
};

const controlStyle = {
  padding: '0 12px',
  border: `2px solid ${theme.colors.charcoal700}`,
  '> div': { color: theme.colors.white },
  svg: { fill: theme.colors.white, color: theme.colors.white },
};

const menuStyle = {
  background: theme.colors.charcoal900,
  color: theme.colors.white,
  '> li': {
    background: theme.colors.charcoal900,
    '&:hover': {
      background: theme.colors.charcoal700,
    },
  },
};

const TrackerMultiAutocomplete: FC<TrackerMultiAutocompleteProps> = ({
  placeholder,
  items,
  selectedItems: selectedItemsProp,
  containerStyles,
  renderFooter,
  ...props
}) => {
  // Need to be reference equal for downshift to match them
  const selectedItemValues = selectedItemsProp
    ? selectedItemsProp.map((item) => item.value)
    : undefined;
  const mappedSelectedItems = selectedItemValues
    ? items?.filter((item) => selectedItemValues.includes(item.value))
    : undefined;
  const { controlProps, menuProps, setSelectedItems } = useMultiAutocomplete({
    items,
    selectedItems: mappedSelectedItems,
    ...props,
  });

  const { getItemProps } = menuProps;

  return (
    <div
      style={{
        width: '40%',
        marginRight: 8,
        marginBottom: 8,
        ...containerStyles,
      }}
    >
      <MultiAutocompleteControl
        {...controlProps}
        placeholder={placeholder}
        style={controlStyle}
      />
      <MultiSelectMenu
        {...menuProps}
        getItemProps={(props) => {
          return getItemProps({
            ...props,
            style: menuItemStyle,
          });
        }}
        renderFooter={() => (
          <Footer>
            <ClearButton
              onClick={() => setSelectedItems([])}
              style={{
                '&:hover': {
                  background: theme.colors.charcoal700,
                },
              }}
            />
          </Footer>
        )}
        style={menuStyle}
      />
    </div>
  );
};

TrackerMultiAutocomplete.propTypes = {
  placeholder: PropTypes.string,
};
export default TrackerMultiAutocomplete;
