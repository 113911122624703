import React from 'react';
import TrackerMultiAutocomplete from '../TrackerMultiAutocomplete';
import { DefaultItem } from '@adsk/alloy-react-dropdown';
import { getAllPropsForComponent } from 'src/utils';
import { ComponentData, SuspendableFetch } from 'src/types';
import useComponent from 'src/hooks/useComponent';

type PropPickerProps = {
  allComponentsResource: SuspendableFetch<ComponentData[]>;
  componentId: string | undefined;
  propFilter: DefaultItem[];
  setPropFilter: (filter: DefaultItem[] | undefined) => void;
};

const PropPicker = ({
  allComponentsResource,
  componentId,
  propFilter,
  setPropFilter,
}: PropPickerProps) => {
  const components = allComponentsResource?.read();

  const component = useComponent({ components, componentId });

  if (!component) {
    return <div>No matching component for: {componentId}</div>;
  }

  if (!component) {
    return <div>No matching component for: {componentId}</div>;
  }

  const allProps = getAllPropsForComponent(component);

  const allPropFilterOptions = allProps.map((prop) => {
    return { label: prop.name, value: prop.name };
  });

  return (
    <TrackerMultiAutocomplete
      items={allPropFilterOptions}
      selectedItems={propFilter}
      onSelectedItemsChange={({ selectedItems }) =>
        setPropFilter(selectedItems)
      }
      placeholder="Filter components by props implemented..."
    />
  );
};

export default PropPicker;
