import React, { FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';

import Badge from '@adsk/alloy-react-badge';
import { LogoGitHubIcon, GlobeIcon } from '@adsk/alloy-react-icon';
import Tooltip from '@adsk/alloy-react-tooltip';

import { ComponentData, Instance } from 'src/types';
import { getGithubUrlForInstance, getPackageVersionsInRepo } from 'src/utils';

import ExternalLink from '../ExternalLink';
import TrackerCard from '../TrackerCard/TrackerCard';
import VersionBadge from '../VersionBadge/VersionBadge';
import { ProjectData } from '../../../../common/types';
import { PLATFORM_TYPES } from '../../pages/Components/Components';

export const groupByProjectName = (instance: Instance) =>
  instance.project?.name || undefined;

const getFeatureByPath = (instance: Instance, index: number) => {
  const file = instance.location?.file;
  const directories = file.split('/').filter((dir) => dir !== '');

  if (directories.length > index) {
    return directories[index];
  }

  return undefined;
};

export const groupByAndroidPath = (instance: Instance) => {
  return getFeatureByPath(instance, 4);
};

export const groupByIosPath = (instance: Instance) => {
  return getFeatureByPath(instance, 7);
};

const groupInstancesByRepo = (instances: Instance[], platformType?: string) => {
  let groupFunction = groupByProjectName;

  switch (platformType) {
    case PLATFORM_TYPES.WEB:
      groupFunction = groupByProjectName;
      break;
    case PLATFORM_TYPES.ANDROID:
      groupFunction = groupByAndroidPath;
      break;
    case PLATFORM_TYPES.IOS:
      groupFunction = groupByIosPath;
      break;
    default:
      groupFunction = groupByProjectName;
      break;
  }

  const entries = Object.entries(groupBy(instances, groupFunction));

  return entries.reduce((result: ComponentData[], [groupKey, instances]) => {
    result.push({
      name: groupKey,
      instances,
    });
    return result;
  }, []);
};

const InstancesPackageVersions: FC<{
  component: ComponentData;
  data: ProjectData;
  platformType?: string;
}> = ({ component, data, platformType }) => {
  const groupedInstances = useMemo(
    () => groupInstancesByRepo(component.instances, platformType),
    [component, platformType]
  );
  return (
    <div
      style={{
        padding: '0px 24px 12px 24px',
        display: 'flex',
        flexFlow: 'column nowrap',
      }}
    >
      {groupedInstances.map((repo, index) => {
        const versions = getPackageVersionsInRepo(repo.instances);

        return (
          <TrackerCard
            key={index}
            title={
              <>
                {versions?.globalImport && (
                  <Tooltip
                    variant={Tooltip.VARIANTS.LIGHT}
                    content={
                      <span>
                        This component is being imported through Alloy React
                        main package
                      </span>
                    }
                  >
                    <GlobeIcon size={16} />
                  </Tooltip>
                )}
                <Badge style={{ marginRight: 8 }}>
                  {repo.instances.length}
                </Badge>
                <span>{repo.name}</span>
                {versions?.mainPkg && (
                  <VersionBadge pkg={versions?.mainPkg} data={data} />
                )}
              </>
            }
            headerStyle={{
              gap: 8,
            }}
          >
            {repo.instances.map((instance, i) => {
              const instanceUrl = instance.project
                ? getGithubUrlForInstance(
                    instance,
                    instance.project,
                    platformType === PLATFORM_TYPES.WEB ? undefined : 'dev'
                  )
                : undefined;
              const filename = instance.location.file.substring(
                instance.location.file.lastIndexOf('/') + 1
              );
              const lineNumber = instance.location.start.line;
              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBlock: 16,
                  }}
                >
                  <LogoGitHubIcon size={20} style={{ marginRight: 10 }} />
                  <ExternalLink href={instanceUrl || '#'}>
                    <span style={{ marginRight: 12 }}>
                      {`${filename}#L${lineNumber}`}
                    </span>
                  </ExternalLink>
                </div>
              );
            })}
          </TrackerCard>
        );
      })}
    </div>
  );
};

InstancesPackageVersions.propTypes = {
  component: PropTypes.shape({
    name: PropTypes.string.isRequired,
    instances: PropTypes.array.isRequired,
  }).isRequired,
};

export default InstancesPackageVersions;
