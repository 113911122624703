import React, { useState, useContext, Suspense } from 'react';
import styled from 'styled-components';

import { ButtonGroup } from '@adsk/alloy-react-button';
import { RadioButton, RadioGroup } from '@adsk/alloy-react-radio-button';

import {
  Sort,
  SortButton,
  SORT_FILTERS,
  cycleSort,
} from 'src/components/SortButton';
import { SortFilter } from 'src/components/SortButton/consts';

import CustomizationsAccordion from 'src/components/CustomizationsAccordion';
import {
  GROUPING_COMPONENT,
  GROUPING_PROJECT,
} from 'src/pages/Customizations/helpers';
import CustomizationsTotals from 'src/components/CustomizationsTotals';
import TrackerContext from 'src/TrackerContext';
import ProgressRing from '@adsk/alloy-react-progress-ring';

const StyledRadioWrapper = styled.label`
  align-items: center;
  display: flex;
  margin-right: 12px;
`;

const StyledRadioLabel = styled.label`
  padding-left: 7px;
`;

function Customizations() {
  const [grouping, setGrouping] = useState(GROUPING_PROJECT);
  const [sortOrder, setSortOrder] = useState<Sort | undefined>();
  const [sortFilter, setSortFilter] = useState<SortFilter | undefined>();

  const resetSort = () => setSortOrder(undefined);

  const trackerContext = useContext(TrackerContext);

  return (
    <div
      style={{
        marginTop: 32,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <h2>Customizations</h2>
      {trackerContext.allWebComponents && (
        <Suspense fallback={<ProgressRing />}>
          <CustomizationsTotals
            allComponentsResource={trackerContext.allWebComponents}
          />
        </Suspense>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <RadioGroup
          value={grouping}
          onChange={(nextValue) => {
            setGrouping(nextValue || '');
          }}
          variant="horizontal"
        >
          <StyledRadioWrapper>
            <RadioButton value={GROUPING_PROJECT} />
            <StyledRadioLabel>Project</StyledRadioLabel>
          </StyledRadioWrapper>
          <StyledRadioWrapper>
            <RadioButton value={GROUPING_COMPONENT} />
            <StyledRadioLabel>Component</StyledRadioLabel>
          </StyledRadioWrapper>
        </RadioGroup>
        <ButtonGroup<SortFilter> onChange={setSortFilter} value={sortFilter}>
          <SortButton
            sortOrder={
              sortFilter === SORT_FILTERS.INSTANCES ? sortOrder : undefined
            }
            onClick={() => {
              resetSort();
              setSortOrder(cycleSort(sortOrder));
            }}
            defaultLabel="Instances"
            value={SORT_FILTERS.INSTANCES}
          />
          {grouping === GROUPING_PROJECT && (
            <SortButton
              sortOrder={
                sortFilter === SORT_FILTERS.COUNT ? sortOrder : undefined
              }
              onClick={() => {
                resetSort();
                setSortOrder(cycleSort(sortOrder));
              }}
              defaultLabel="Components"
              value={SORT_FILTERS.COUNT}
            />
          )}
        </ButtonGroup>
      </div>
      {trackerContext.data && trackerContext.allWebComponents && (
        <Suspense fallback={<ProgressRing />}>
          <CustomizationsAccordion
            subgroupBy={grouping === GROUPING_COMPONENT ? 'project' : 'name'}
            countSubgroups={grouping === GROUPING_PROJECT}
            grouping={grouping}
            sortOrder={sortOrder}
            sortFilter={sortFilter}
            projectDataResource={trackerContext.data}
            allComponentsResource={trackerContext.allWebComponents}
          />
        </Suspense>
      )}
    </div>
  );
}

export default Customizations;
