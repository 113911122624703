import React from 'react';

import theme from '@adsk/alloy-react-theme';
import EmptyState from '@adsk/alloy-react-empty-state';

import { getSlackUrlForChannel } from 'src/utils';
import ExternalLink from 'src/components/ExternalLink';
import { SLACK_CHANNEL } from 'src/consts';

function ErrorPage() {
  return (
    <EmptyState
      style={{
        color: theme.colors.white,
        backgroundColor: theme.colors.charcoal800,
      }}
      title="There was an issue loading the latest data."
      description={
        <>
          Please try again later or continue to{' '}
          <code style={{ fontSize: 12, padding: 3 }}>cmd + r</code> for as long
          as you&lsquo;d like.
        </>
      }
    >
      Contact
      <ExternalLink
        href={getSlackUrlForChannel(SLACK_CHANNEL)}
        style={{ marginLeft: 8 }}
      >
        {SLACK_CHANNEL}
      </ExternalLink>{' '}
      if the problem persists.
    </EmptyState>
  );
}

export default ErrorPage;
