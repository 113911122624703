import React from 'react';
import InstanceCard from '../InstanceCard/InstanceCard';
import { ComponentData, Instance, SuspendableFetch } from 'src/types';
import { DefaultItem } from '@adsk/alloy-react-dropdown';
import theme from '@adsk/alloy-react-theme';
import { ProjectData } from '../../../../common/types';
import useComponent from 'src/hooks/useComponent';

const getProjectFromRelativePath = (path: string) => {
  // If we ever change the location alloy-scan checks out repos to this could break
  return path.split('/')[0];
};

const getOrgForProject = (project: string, projectData: ProjectData) => {
  return (
    projectData.repos.find((repo) => repo.name === project)?.org || 'BIM360'
  );
};

const bySelectedProps = ({
  instance,
  propFilter,
}: {
  instance: Instance;
  propFilter: DefaultItem[];
}) => {
  const propsUsed = Object.keys(instance.props);
  const filterPropNames = propFilter.map((p) => p.value);

  return filterPropNames.every((filterProp) => propsUsed.includes(filterProp));
};

const bySelectedRepo = ({
  instance,
  repoFilter,
}: {
  instance: Instance;
  repoFilter: string | undefined;
}) =>
  repoFilter === null ||
  repoFilter === undefined ||
  repoFilter === 'total' ||
  repoFilter === instance.project?.name;

const byAllFilters = ({
  instance,
  repoFilter,
  propFilter,
}: {
  instance: Instance;
  repoFilter: string | undefined;
  propFilter: DefaultItem[];
}) =>
  bySelectedRepo({ instance, repoFilter }) &&
  bySelectedProps({ instance, propFilter });

type ComponentInstancesProps = {
  projectDataResource: SuspendableFetch<ProjectData>;
  allComponentsResource: SuspendableFetch<ComponentData[]>;
  componentId: string | undefined;
  repoFilter: string | undefined;
  propFilter: DefaultItem[];
};

const ComponentInstances = ({
  projectDataResource,
  allComponentsResource,
  componentId,
  repoFilter,
  propFilter,
}: ComponentInstancesProps) => {
  const projectData = projectDataResource.read();
  const components = allComponentsResource?.read();

  const component = useComponent({ components, componentId });

  if (!component) {
    return <div>No matching component for: {componentId}</div>;
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        color: theme.colors.charcoal900,
        ...theme.typography.bodyMedium,
      }}
    >
      {component.instances
        ?.filter((instance) =>
          byAllFilters({ instance, repoFilter, propFilter })
        )
        .map((instance, index) => {
          const projectName = getProjectFromRelativePath(
            instance.location.file
          );
          const project = projectData.repos.find(
            (repo) => repo.name === projectName
          );
          const org = getOrgForProject(projectName, projectData);

          return (
            <InstanceCard
              key={index}
              instance={instance}
              org={org}
              project={project}
            />
          );
        })}
    </div>
  );
};

export default ComponentInstances;
