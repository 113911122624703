import React from 'react';
import ComponentsAccordion from '../ComponentsAccordion';
import { ComponentData, SuspendableFetch } from 'src/types';
import { PLATFORM_TYPES } from 'src/pages/Components/Components';
import useComponentsInProject from 'src/hooks/useComponentsInProject';

type ProjectComponentsAccordionProps = {
  allComponentsResource: SuspendableFetch<ComponentData[]>;
  projectId: string | undefined;
};

const ProjectComponentsAccordion = ({
  allComponentsResource,
  projectId,
}: ProjectComponentsAccordionProps) => {
  const components: ComponentData[] = useComponentsInProject({
    projectId,
    allComponents: allComponentsResource?.read(),
  });

  return (
    <ComponentsAccordion
      components={components}
      projectId={projectId}
      platformType={PLATFORM_TYPES.WEB}
    />
  );
};

export default ProjectComponentsAccordion;
