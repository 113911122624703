import React from 'react';
import useComponent from 'src/hooks/useComponent';
import { getAllPropsForComponent, mostCommonInArray } from 'src/utils';
import { ComponentData, SuspendableFetch } from '../../types';

type ComponentPropsProps = {
  allComponentsResource: SuspendableFetch<ComponentData[]>;
  componentId: string | undefined;
};

const ComponentProps = ({
  allComponentsResource,
  componentId,
}: ComponentPropsProps) => {
  const components = allComponentsResource?.read();

  const component = useComponent({ components, componentId });

  if (!component) {
    return <div>No matching component for: {componentId}</div>;
  }

  const allProps = getAllPropsForComponent(component);

  return (
    <ul
      style={{
        paddingLeft: '15px',
        maxHeight: '450px',
        width: '300px',
        overflowY: 'auto',
      }}
    >
      {allProps.map((prop) => (
        <li key={prop.name}>
          <strong>{prop.name}</strong>: {mostCommonInArray(prop.types)}
        </li>
      ))}
    </ul>
  );
};

export default ComponentProps;
