import React, { useMemo } from 'react';
import groupBy from 'lodash.groupby';

import Accordion, { AccordionItem } from '@adsk/alloy-react-accordion';
import Badge from '@adsk/alloy-react-badge';
import { LogoGitHubIcon, GlobeIcon } from '@adsk/alloy-react-icon';
import theme from '@adsk/alloy-react-theme';
import Tooltip from '@adsk/alloy-react-tooltip';

import ExternalLink from 'src/components/ExternalLink';
import TrackerCard from 'src/components/TrackerCard/TrackerCard';
import VersionBadge from 'src/components/VersionBadge/VersionBadge';
import { ComponentData, OverrideInstance, SuspendableFetch } from 'src/types';
import {
  GROUPING_COMPONENT,
  GROUPING_PROJECT,
  groupInstances,
  isCustomizationProp,
} from 'src/pages/Customizations/helpers';
import { ProjectData, ProjectPackage } from '../../../../common/types';
import { accordionItemStyle } from '../../pages/Projects/ProjectsAccordion';
import { SORT_FILTERS, Sort } from '../SortButton';
import { SortFilter } from '../SortButton/consts';
import useComponentOverrideInstances from 'src/hooks/useComponentOverrideInstances';
import HideContentsAccordionItem from '../HideContentsAccordionItem';

const accordionStyle = {
  ...accordionItemStyle,
  '> button': {
    height: 44,
    '&:focus': {
      color: theme.colors.white,
    },
  },
  fontSize: 14,
  lineHeight: '20px',
};

const ALLOY_REACT_PACKAGE = '@adsk/alloy-react';
const CustomizationsAccordion = ({
  grouping,
  sortOrder,
  sortFilter,
  subgroupBy,
  countSubgroups,
  projectDataResource,
  allComponentsResource,
}: {
  grouping: string;
  sortOrder: Sort | undefined;
  sortFilter: SortFilter | undefined;
  subgroupBy: string;
  countSubgroups?: boolean;
  projectDataResource: SuspendableFetch<ProjectData>;
  allComponentsResource: SuspendableFetch<ComponentData[]>;
}) => {
  const projectData = projectDataResource.read();
  const allComponents = allComponentsResource?.read();

  const componentsOverrideInstances = useComponentOverrideInstances({
    allComponents,
  });

  const overridesByRepo = useMemo(() => {
    return groupInstances(componentsOverrideInstances, 'project');
  }, [componentsOverrideInstances]);

  const overridesByComponent = useMemo(() => {
    return groupInstances(componentsOverrideInstances, 'name');
  }, [componentsOverrideInstances]);

  const groupedInstances = useMemo(() => {
    return grouping === GROUPING_PROJECT
      ? overridesByRepo
      : grouping === GROUPING_COMPONENT
      ? overridesByComponent
      : [];
  }, [grouping, overridesByRepo, overridesByComponent]);

  const sortedInstances = useMemo(() => {
    const groups = groupedInstances || [];
    switch (sortFilter) {
      case SORT_FILTERS.INSTANCES:
        if (sortOrder === Sort.asc) {
          groups.sort((a, b) => b.instances.length - a.instances.length);
        }
        if (sortOrder === Sort.desc) {
          groups.sort((a, b) => a.instances.length - b.instances.length);
        }
        break;
      case SORT_FILTERS.COUNT:
        if (sortOrder === Sort.asc) {
          groups.sort(
            (a, b) =>
              groupInstances(a.instances, 'name').length -
              groupInstances(b.instances, 'name').length
          );
        }
        if (sortOrder === Sort.desc) {
          groups.sort(
            (a, b) =>
              groupInstances(b.instances, 'name').length -
              groupInstances(a.instances, 'name').length
          );
        }
        break;
      default:
        break;
    }
    return groups;
  }, [groupedInstances, sortOrder, sortFilter]);

  return (
    <Accordion
      initialExpanded={[Number.MAX_SAFE_INTEGER]}
      renderItem={HideContentsAccordionItem}
    >
      {sortedInstances.map((item) => {
        const subCollection = groupInstances(item.instances, subgroupBy);
        return (
          <AccordionItem
            key={item.key}
            style={accordionStyle}
            title={item.key}
            label={
              <div>
                <Badge style={{ marginRight: 8 }}>
                  {item.instances.length}
                </Badge>
                {countSubgroups && (
                  <Badge
                    style={{ color: 'white' }}
                    variant={Badge.VARIANTS.OUTLINE}
                  >
                    {subCollection.length}
                  </Badge>
                )}
              </div>
            }
          >
            {subCollection.map((item) => {
              const versions = Object.entries(
                groupBy(item.instances, (instance: OverrideInstance) =>
                  instance.pkg
                    ? `${instance.pkg.version} ${instance.pkg.name}`
                    : undefined
                )
              ).filter(([key]) => key !== 'undefined');
              const multipleImports = versions.length > 1;

              let mainPkg: ProjectPackage | undefined;
              let globalImport = false;
              if (versions.length === 1) {
                const [pkg, instances] = versions[0];
                globalImport = pkg.split(' ')[1] === ALLOY_REACT_PACKAGE;
                mainPkg = instances[0].pkg;
              }
              if (multipleImports) {
                const globalInstances = versions.find(
                  (pkgData) => pkgData[0].split(' ')[1] === ALLOY_REACT_PACKAGE
                );
                if (globalInstances && versions.length === 2) {
                  const pkgInstances = versions.find(
                    (pkgData) =>
                      pkgData[0].split(' ')[1] !== ALLOY_REACT_PACKAGE
                  );
                  mainPkg = pkgInstances && pkgInstances[1][0].pkg;
                }
                globalImport = !!globalInstances;
              }

              return (
                <TrackerCard
                  key={item.key}
                  title={
                    <>
                      {globalImport && (
                        <Tooltip
                          variant={Tooltip.VARIANTS.LIGHT}
                          content={
                            <span>
                              This component is being imported through Alloy
                              React main package
                            </span>
                          }
                        >
                          <GlobeIcon size={16} />
                        </Tooltip>
                      )}
                      {mainPkg && (
                        <VersionBadge pkg={mainPkg} data={projectData} />
                      )}
                      <span>{item.key}</span>
                      <Badge style={{ marginRight: 8 }}>
                        {item.instances.length}
                      </Badge>
                    </>
                  }
                  headerStyle={{
                    gap: 8,
                  }}
                >
                  {item.instances
                    .sort(
                      (a, b) =>
                        Object.keys(b.props).filter(isCustomizationProp)
                          .length -
                        Object.keys(a.props).filter(isCustomizationProp).length
                    )
                    .map((instance, i) => (
                      <div
                        key={i}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBlock: 16,
                        }}
                      >
                        <LogoGitHubIcon size={20} style={{ marginRight: 10 }} />
                        <ExternalLink href={instance.instanceUrl || '#'}>
                          <span style={{ marginRight: 12 }}>
                            {`${instance.filename}#L${instance.lineNumber}`}
                          </span>
                        </ExternalLink>
                        {instance.props &&
                          Object.keys(instance.props)
                            .filter((prop) => isCustomizationProp(prop))
                            .map((prop) => (
                              <Badge style={{ marginRight: 8 }} key={prop}>
                                {prop}
                              </Badge>
                            ))}
                      </div>
                    ))}
                </TrackerCard>
              );
            })}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default CustomizationsAccordion;
