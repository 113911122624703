export default {
  GIT_SECRET_KEY: 'ALLOY_TRACKER_GIT_TOKEN',
  GIT_ADSK_SECRET_KEY: 'ALLOY_TRACKER_ADSK_GIT_TOKEN',
  NPM_USERNAME_KEY: 'ALLOY_TRACKER_NPM_USERNAME',
  NPM_TOKEN_KEY: 'ALLOY_TRACKER_NPM_TOKEN',
  GIT_ORG: 'BIM360',
  PACKAGE: '@adsk/alloy',
  RETRIES: 3,
  AWS_REGION: 'us-west-2',
  AWS_SECRET_KEY: '',
  AWS_ACCESS_KEY: '',
  INACTIVE_REPOS: [
    'acc-as-built-demo',
    'acc-coordination-ui',
    'acc-issues-test',
    'acc-viewer-whitesource',
    'acs-demo-app',
    'acs-library-test',
    'acs-progress-tracking-libraries',
    'acs-projects',
    'acs-schedule-web-cra',
    'alloy-table',
    'bazinga-acc-plugins-mfe',
    'bim360-coordination-ui',
    'create-react-app-alloy',
    'design-system-components',
    'ecclesia-ui-web',
    'GoldenGateExample',
    'model-coordination-common',
    'Outlook-Plugin-ACC',
    'page-components',
    'qto-machine-learning-showcase',
    'rc-acs-docs',
    'risk-client-ci',
    'shredder-client-example',
    'testdash',
  ],
  MOBILE_REPOS: [
    {
      org: 'plangrid',
      name: 'build-mobile',
      url: 'https://github.com/plangrid/build-mobile',
      packages: [],
      isLibrary: false,
    },
  ],
  ADSK_GIT_ORGS: [
    'BIM360',
    'plangrid',
    'fluent',
    'acs-offsite-construction',
    'revit',
  ],
  PUBLIC_GIT_ORGS: ['plangrid', 'BuildingConnected'],
  LOG_LEVEL: 3,
  S3_FILENAME: 'assets-no-cache/repos.json',
  S3_BUCKET: 'wptrcker-c-uw2-acc-web-platform-tracker-dev-cloudfront',
  CLOUDFRONT_DISTRIBUTION_ID: 'ELZ7U1T7GBWIL',
  TRACKER_URL: 'https://alloy.acc-qa.autodesk.com',
  DB_TABLE: 'alloy-tracker-serverless-db',
} as const;

export const LOG_PREFIX = '@alloy-tracker //';
