import React, { FC } from 'react';
import styled from 'styled-components';

import theme from '@adsk/alloy-react-theme';

import VersionBadge from 'src/components/VersionBadge/VersionBadge';
import { ProjectData, ProjectPackage } from '../../../../common/types';
import { getCategorizedPackages } from 'src/utils';
import { SuspendableFetch } from 'src/types';
import EmptyState from '@adsk/alloy-react-empty-state';

const Heading = styled.span`
  color: ${theme.colors.white};
  margin-top: 8px;
  margin-bottom: 8px;
`;

const PackageVersion: FC<{
  pkg: ProjectPackage;
  data: ProjectData;
}> = ({ pkg, data }) => (
  <div style={{ display: 'flex', height: 32, alignItems: 'center' }}>
    <VersionBadge pkg={pkg} data={data} />
    <span style={{ marginLeft: 10, color: theme.colors.charcoal300 }}>
      {pkg.name}
    </span>
  </div>
);

const ProjectPackageVersions: FC<{
  projectDataResource: SuspendableFetch<ProjectData>;
  projectId: string | undefined;
}> = ({ projectDataResource, projectId }) => {
  const project = projectDataResource
    .read()
    .repos.find((repo) => repo.name === projectId);

  if (!project) {
    return (
      <EmptyState
        title="Not found"
        style={{ backgroundColor: theme.colors.black }}
        description={`No matching project for: ${projectId}`}
      />
    );
  }

  const { designSystemPackages, webProdExpPackages, otherPackages } =
    getCategorizedPackages(project.packages);
  return (
    <div
      style={{
        padding: '0px 24px 12px 24px',
        display: 'flex',
        flexFlow: 'column nowrap',
      }}
    >
      {designSystemPackages.length > 0 && (
        <>
          <Heading>Design System</Heading>
          {designSystemPackages.map((pack, index) => (
            <PackageVersion
              key={`${project.name}-${pack.name}-${index}`}
              pkg={pack}
              data={projectDataResource.read()}
            />
          ))}
        </>
      )}
      {webProdExpPackages.length > 0 && (
        <>
          <Heading>Product Experience</Heading>
          {webProdExpPackages.map((pack, index) => (
            <PackageVersion
              key={`${project.name}-${pack.name}-${index}`}
              pkg={pack}
              data={projectDataResource.read()}
            />
          ))}
        </>
      )}
      {otherPackages.length > 0 && (
        <>
          <Heading>Other</Heading>
          {otherPackages.map((pack, index) => (
            <PackageVersion
              key={`${project.name}-${pack.name}-${index}`}
              pkg={pack}
              data={projectDataResource.read()}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ProjectPackageVersions;
