import React from 'react';

import ProgressRing from '@adsk/alloy-react-progress-ring';

function LoadingPage() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ProgressRing size={ProgressRing.SIZES.XLARGE} />
    </div>
  );
}

export default LoadingPage;
