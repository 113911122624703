import React, { Suspense, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { DefaultItem } from '@adsk/alloy-react-dropdown';

import {
  SortButton,
  Sort,
  SORT_FILTERS,
  cycleSort,
} from 'src/components/SortButton';
import ComponentPicker from 'src/components/ComponentPicker';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import ComponentsData from 'src/components/ComponentsData';
import TrackerContext from 'src/TrackerContext';

export const PLATFORM_TYPES = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
};

function Components({ platformType }: { platformType: string }) {
  const [selectedComponents, setSelectedComponents] = useState<DefaultItem[]>(
    []
  );
  const [sortOrder, setSortOrder] = useState<Sort | undefined>();

  const trackerContext = useContext(TrackerContext);

  let allComponents;
  switch (platformType) {
    case PLATFORM_TYPES.WEB:
      allComponents = trackerContext.allWebComponents;
      break;
    case PLATFORM_TYPES.ANDROID:
      allComponents = trackerContext.allAndroidComponents;
      break;
    case PLATFORM_TYPES.IOS:
      allComponents = trackerContext.allIosComponents;
      break;
    default:
      allComponents = trackerContext.allWebComponents;
      break;
  }

  return (
    <div
      style={{
        paddingTop: 32,
        display: 'flex',
        justifyContent: 'flex-start',
        flexFlow: 'column nowrap',
      }}
    >
      <div style={{ display: 'flex' }}>
        {allComponents && (
          <ComponentPicker
            allComponentsResource={allComponents}
            selectedComponents={selectedComponents}
            setSelectedComponents={setSelectedComponents}
          />
        )}
        <SortButton
          sortOrder={sortOrder}
          onClick={() => setSortOrder(cycleSort(sortOrder))}
          defaultLabel="A-Z"
          toggleLabel="Z-A"
          value={SORT_FILTERS.ALPHA}
        />
      </div>
      {trackerContext.data && allComponents && (
        <Suspense fallback={<ProgressRing />}>
          <ComponentsData
            allComponentsResource={allComponents}
            platformType={platformType}
            selectedComponents={selectedComponents}
            sortOrder={sortOrder}
            projectId={undefined}
          />
        </Suspense>
      )}
    </div>
  );
}

Components.propTypes = {
  isMobile: PropTypes.string,
};

export default Components;
