import React from 'react';
import theme from '@adsk/alloy-react-theme';

import GithubLink from '../IconLinks/GithubLink';
import Heading from '../Layout/Heading';
import SlackLink from '../IconLinks/SlackLink';
import JiraLink from '../IconLinks/JiraLink';
import { SuspendableFetch } from 'src/types';
import { ProjectData } from '../../../../common/types';
import EmptyState from '@adsk/alloy-react-empty-state/es/EmptyState';

type ProjectHeaderProps = {
  projectDataResource: SuspendableFetch<ProjectData>;
  projectId: string | undefined;
};

const ProjectHeader = ({
  projectDataResource,
  projectId,
}: ProjectHeaderProps) => {
  const project = projectDataResource
    .read()
    .repos.find((repo) => repo.name === projectId);

  if (!project) {
    return (
      <EmptyState
        title="Not found"
        style={{ backgroundColor: theme.colors.black }}
        description={`No matching project for: ${projectId}`}
      />
    );
  }

  return (
    <Heading>
      <GithubLink
        url={project.url}
        size={24}
        style={{ marginRight: 10, color: theme.colors.white }}
      />
      <SlackLink
        project={project}
        size={20}
        fillColor={theme.colors.white}
        style={{ marginRight: 10, color: theme.colors.white }}
      />
      {project.jiraCodes && (
        <JiraLink
          code={project.jiraCodes[0]}
          size={20}
          style={{ marginRight: 10, color: theme.colors.white }}
        />
      )}
      {projectId}
    </Heading>
  );
};

export default ProjectHeader;
