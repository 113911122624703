import React, { FC } from 'react';
import PropTypes from 'prop-types';
import theme from '@adsk/alloy-react-theme';

import {
  useAutocomplete,
  AutocompleteControl,
  DefaultItem,
  UseAutocompleteProps,
  Menu,
  MenuGroupHeader,
  MenuItem,
} from '@adsk/alloy-react-dropdown';

type TrackerAutocompleteProps = UseAutocompleteProps<DefaultItem> & {
  placeholder?: string;
  disabled?: boolean;
};

const menuStyle = {
  background: theme.colors.charcoal900,
  color: theme.colors.white,
  '> p': {
    background: theme.colors.charcoal700,
  },
  '> li': {
    background: theme.colors.charcoal900,
    '&:hover': {
      background: theme.colors.charcoal700,
    },
  },
};

const TrackerAutocomplete: FC<TrackerAutocompleteProps> = ({
  placeholder,
  disabled,
  ...props
}) => {
  const { controlProps, menuProps: allMenuProps } = useAutocomplete({
    ...props,
  });

  const { menuItems, isGroupHeader, getItemProps, ...menuProps } = allMenuProps;

  return (
    <div
      style={{
        width: '40%',
        marginRight: 8,
        marginBottom: 8,
      }}
    >
      <AutocompleteControl
        {...controlProps}
        disabled={disabled}
        placeholder={placeholder}
        style={[
          {
            padding: '0 12px',
            border: `2px solid ${theme.colors.charcoal700}`,
            '> div': { color: theme.colors.white },
            svg: {
              fill: theme.colors.white,
              color: theme.colors.white,
            },
          },
          {
            ...(disabled && {
              background: theme.colors.charcoal700,
              '> div': { color: theme.colors.charcoal500 },
            }),
          },
        ]}
      />
      <Menu {...menuProps} style={menuStyle}>
        {menuItems.map((itemOrGroupHeader, i) =>
          isGroupHeader(itemOrGroupHeader) ? (
            <MenuGroupHeader key={i}>{itemOrGroupHeader.label}</MenuGroupHeader>
          ) : (
            <MenuItem key={i} {...getItemProps({ item: itemOrGroupHeader })} />
          )
        )}
      </Menu>
    </div>
  );
};

TrackerAutocomplete.propTypes = {
  placeholder: PropTypes.string,
};

export default TrackerAutocomplete;
