import React, { Suspense, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import theme from '@adsk/alloy-react-theme';
import { DefaultItem } from '@adsk/alloy-react-dropdown';

import TrackerContext from 'src/TrackerContext';
import { ChartSvg } from 'src/components/InteractiveChart';
import { UsageData } from 'src/types';
import TrackerAutocomplete from 'src/components/TrackerAutocomplete';
import Heading from 'src/components/Layout/Heading';
import ComponentInstances from 'src/components/ComponentInstances';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import ComponentProps from 'src/components/ComponentProps';
import PropPicker from 'src/components/PropPicker';

function Component() {
  const { componentId } = useParams<{ componentId: string }>();
  const context = useContext(TrackerContext);

  const data = context.data?.read();
  const usageData = context.usageData?.read();

  const allRepos: DefaultItem[] = useMemo(() => {
    if (data && data.repos) {
      const temp = data.repos
        .map((r) => {
          return { value: r.name, label: r.name };
        })
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

      temp.unshift({ value: 'total', label: 'All' });
      return temp;
    }
    return [];
  }, [data]);

  const [propFilter, setPropFilterRaw] = useState<DefaultItem[]>([]);
  const [repoFilter, setRepoFilter] = useState<DefaultItem | null>(null);

  const setPropFilter = (filter: DefaultItem[] | undefined) => {
    setPropFilterRaw(filter || []);
  };

  const usageDataForComponent: UsageData | undefined = useMemo(
    () =>
      usageData
        ? {
            y: usageData.y,
            series:
              usageData.series.filter((s) => s.name === componentId) || [],
            dates: usageData.dates,
          }
        : undefined,
    [componentId, usageData]
  );

  /* eslint-disable */
  return (
    <>
      <Heading>{componentId}</Heading>
      <div
        style={{
          color: theme.colors.white,
          fontFamily: 'monospace',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 8,
        }}
      >
        <div style={{ width: '120' }}>
          Props implemented:
          {context.allWebComponents && (
            <Suspense fallback={<ProgressRing />}>
              <ComponentProps
                allComponentsResource={context.allWebComponents}
                componentId={componentId}
              />
            </Suspense>
          )}
        </div>
        <div
          style={{
            paddingLeft: '7%',
          }}
        >
          {usageDataForComponent && (
            <ChartSvg
              usageData={usageDataForComponent}
              highlightedComponents={
                componentId
                  ? [{ value: componentId, label: componentId }]
                  : undefined
              }
              setHighlightedComponents={() => {}}
              selectedRepo={repoFilter}
            />
          )}
        </div>
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '15px',
            marginBottom: '15px',
          }}
        >
          {context.allWebComponents && (
            <Suspense fallback={<ProgressRing />}>
              <PropPicker
                allComponentsResource={context.allWebComponents}
                componentId={componentId}
                propFilter={propFilter}
                setPropFilter={setPropFilter}
              />
            </Suspense>
          )}
          <TrackerAutocomplete
            items={allRepos}
            selectedItem={repoFilter}
            onSelectedItemChange={({ selectedItem }) =>
              setRepoFilter(selectedItem || null)
            }
            placeholder="Filter components by repo..."
          />
        </div>
        {context.data && context.allWebComponents && (
          <Suspense fallback={<ProgressRing />}>
            <ComponentInstances
              projectDataResource={context.data}
              allComponentsResource={context.allWebComponents}
              componentId={componentId}
              repoFilter={repoFilter?.value}
              propFilter={propFilter}
            />
          </Suspense>
        )}
      </div>
    </>
  );
}

export default Component;
