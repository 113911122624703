import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import {
  load,
  loadUsage,
  loadLatestScan,
  loadMobileUsage,
  loadLatestAndroidScan,
  loadLatestIosScan,
  getAllComponents,
} from './utils';
import GlobalStyle from './components/Layout/GlobalStyle';
import Page from './components/Layout/Page';
import TrackerContext from './TrackerContext';
import appRoutes, { AppRoute } from './routes';
import ScrollToTop from './components/ScrollToTop';
import { ProjectData } from '../../common/types';
import { ComponentData, Scan, SuspendableFetch, UsageData } from './types';

const renderRoute = (route: AppRoute) => (
  <Route key={route.path} path={route.path} element={<route.component />} />
);

function App() {
  const [data, setData] = useState<SuspendableFetch<ProjectData>>();
  const [usageData, setUsageData] = useState<SuspendableFetch<UsageData>>();
  const [latestScan, setLatestScan] = useState<SuspendableFetch<Scan>>();
  const [mobileUsageData, setMobileUsageData] =
    useState<SuspendableFetch<UsageData>>();
  const [latestAndroidScan, setLatestAndroidScan] =
    useState<SuspendableFetch<Scan>>();
  const [latestIosScan, setLatestIosScan] = useState<SuspendableFetch<Scan>>();
  const [loadError, setLoadError] = useState(false);
  const [allWebComponents, setAllWebComponents] =
    useState<SuspendableFetch<ComponentData[]>>();
  const [allAndroidComponents, setAllAndroidComponents] =
    useState<SuspendableFetch<ComponentData[]>>();
  const [allIosComponents, setAllIosComponents] =
    useState<SuspendableFetch<ComponentData[]>>();

  useEffect(() => {
    try {
      const dataFromJson = load();
      setData(dataFromJson);
      const usageDataFromJson = loadUsage();
      setUsageData(usageDataFromJson);
      const latestScanFromJson = loadLatestScan();
      setLatestScan(latestScanFromJson);
      const mobileUsageDataFromJson = loadMobileUsage();
      setMobileUsageData(mobileUsageDataFromJson);
      const latestAndroidScanFromJson = loadLatestAndroidScan();
      setLatestAndroidScan(latestAndroidScanFromJson);
      const latestIosScanFromJson = loadLatestIosScan();
      setLatestIosScan(latestIosScanFromJson);
      const allWebComponentsFromJson = getAllComponents({
        projectDataResource: dataFromJson,
        scanDataResource: latestScanFromJson,
      });
      setAllWebComponents(allWebComponentsFromJson);
      const allAndroidComponentsFromJson = getAllComponents({
        projectDataResource: dataFromJson,
        scanDataResource: latestAndroidScanFromJson,
      });
      setAllAndroidComponents(allAndroidComponentsFromJson);
      const allIosComponentsFromJson = getAllComponents({
        projectDataResource: dataFromJson,
        scanDataResource: latestIosScanFromJson,
      });
      setAllIosComponents(allIosComponentsFromJson);
    } catch (err) {
      console.error(err);
      setLoadError(true);
    }
  }, [loadError]);

  return (
    <BrowserRouter>
      <TrackerContext.Provider
        value={{
          data,
          usageData,
          latestScan,
          mobileUsageData,
          latestAndroidScan,
          latestIosScan,
          allWebComponents,
          allAndroidComponents,
          allIosComponents,
        }}
      >
        <GlobalStyle />
        <ScrollToTop />
        <Page>
          <Routes>
            {renderRoute(appRoutes.project)}
            {renderRoute(appRoutes.projectComponent)}
            {renderRoute(appRoutes.component)}
            {renderRoute(appRoutes.error)}
            {loadError && (
              <Route
                path={'*'}
                element={<Navigate to={appRoutes.error.path} />}
              />
            )}
            {renderRoute(appRoutes.landing)}
          </Routes>
        </Page>
      </TrackerContext.Provider>
    </BrowserRouter>
  );
}

export default App;
