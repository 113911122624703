import React, { ComponentProps } from 'react';
import PropTypes from 'prop-types';
import { CSSObject } from 'styled-components';

import theme from '@adsk/alloy-react-theme';
import { BasicButton } from '@adsk/alloy-react-button';
import Tooltip from '@adsk/alloy-react-tooltip';
import { Icon } from '@adsk/alloy-react-icon';
import { getSlackUrlForChannel } from 'src/utils';
import { JSONProject, Project } from '../../../../common/types';

const SlackLink = ({
  project,
  size = 16,
  style,
  fillColor,
}: ComponentProps<typeof BasicButton> & {
  project: Project | JSONProject;
  size?: number;
  fillColor?: string;
}) =>
  project.slackChannels?.length ? (
    <Tooltip
      content={
        <>
          <p>{project.slackChannels.join(', ')}</p>
          <p>{project.codeowners?.join(', ')}</p>
        </>
      }
      getTargetProps={() => ({
        style: { height: size, display: 'flex', alignItems: 'center' },
      })}
    >
      <BasicButton
        style={{
          padding: 0,
          marginRight: 6,
          height: size,
          width: size,
          border: 'none',
          boxShadow: 'none',
          ...(style as CSSObject),
        }}
        onClick={() => {
          if (project.slackChannels?.length) {
            window.open(
              getSlackUrlForChannel(project.slackChannels?.[0]),
              '_blank'
            );
          }
        }}
      >
        <Icon
          style={{ ':hover path': { fill: theme.colors.adskBlue500 } }}
          size={size}
          viewBox="0 0 127 127"
        >
          <path
            d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z"
            fill={fillColor || '#E01E5A'}
          />
          <path
            d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z"
            fill={fillColor || '#36C5F0'}
          />
          <path
            d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z"
            fill={fillColor || '#2EB67D'}
          />
          <path
            d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z"
            fill={fillColor || '#ECB22E'}
          />
        </Icon>
      </BasicButton>
    </Tooltip>
  ) : null;

SlackLink.propTypes = {
  size: PropTypes.number,
  project: PropTypes.shape({
    org: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    packages: PropTypes.array.isRequired,
    slackChannels: PropTypes.array,
    versionsBehind: PropTypes.shape({
      major: PropTypes.number.isRequired,
      minor: PropTypes.number.isRequired,
      patch: PropTypes.number.isRequired,
      equal: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SlackLink;
