import React from 'react';
import { createGlobalStyle } from 'styled-components';

import theme, { Reset } from '@adsk/alloy-react-theme';

const BodyStyle = createGlobalStyle`
  body {
    background: ${theme.colors.charcoal800};
    color: ${theme.colors.charcoal500};
  }
`;

const GlobalStyle = () => (
  <>
    <Reset />
    <BodyStyle />
  </>
);

export default GlobalStyle;
