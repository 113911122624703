import React, { FC } from 'react';
import { FrameworksOrgDependencies } from 'src/types';

import { useRelativeDate } from '@adsk/alloy-react-datetime';

type RepoDependencesProps = {
  resource: {
    read: () => FrameworksOrgDependencies;
  };
};

const LastUpdated = ({ date }: { date: string }) => {
  const relativeDate = useRelativeDate(new Date(date));

  return <h3>Last Updated {relativeDate}</h3>;
};

const RepoDependences: FC<RepoDependencesProps> = ({ resource }) => {
  const { data } = resource.read();

  return (
    <>
      {Object.entries(data).map(([githubOrg, repo]) =>
        Object.entries(repo).map(([repoName, repoData]) => (
          <>
            <h2>
              {githubOrg}: {repoName}
            </h2>
            <LastUpdated date={repoData.harvested_at} />
            <div style={{ display: 'flex' }}>
              Dependencies:
              <ul>
                {repoData.dependencies.length > 0
                  ? repoData.dependencies.map((dep) => (
                      <li key={dep.name}>
                        {dep.name}: {dep.versions}
                      </li>
                    ))
                  : 'None'}
              </ul>
            </div>
          </>
        ))
      )}
    </>
  );
};

export default RepoDependences;
