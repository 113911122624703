import React, { MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import theme from '@adsk/alloy-react-theme';
import BasicButton from '@adsk/alloy-react-button';
import { SortUpIcon, SortDownIcon } from '@adsk/alloy-react-icon';

import { Sort, SortFilter } from './consts';

function SortButton({
  sortOrder,
  onClick,
  value,
  defaultLabel,
  toggleLabel,
}: {
  sortOrder?: Sort;
  onClick: MouseEventHandler;
  value?: SortFilter;
  defaultLabel?: string;
  toggleLabel?: string;
}) {
  return (
    <BasicButton
      onClick={onClick}
      style={{
        border: '2px solid',
        borderColor: sortOrder
          ? theme.colors.green500
          : theme.colors.charcoal700,
        backgroundColor: sortOrder ? theme.colors.green700 + '65' : 'inherit',
        outline: 'none',
        svg: { color: theme.colors.white },
      }}
      value={value}
    >
      {sortOrder === Sort.desc ? (
        <SortUpIcon
          size={16}
          style={{
            marginRight: 8,
          }}
        />
      ) : (
        <SortDownIcon
          size={16}
          style={{
            marginRight: 8,
          }}
        />
      )}
      <span style={{ color: theme.colors.white }}>
        {toggleLabel
          ? sortOrder === undefined || sortOrder === Sort.asc
            ? defaultLabel
            : toggleLabel
          : defaultLabel}
      </span>
    </BasicButton>
  );
}

SortButton.propTypes = {
  sortOrder: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
  defaultLabel: PropTypes.string,
  toggleLabel: PropTypes.string,
};

export default SortButton;
