import React, { useMemo } from 'react';
import StackedBarChart from '../StackedBar/StackedBar';
import ComponentsAccordion from '../ComponentsAccordion';
import { DefaultItem } from '@adsk/alloy-react-dropdown';
import { Sort } from '../SortButton';
import { ComponentData, SuspendableFetch } from 'src/types';

type ComponentsDataProps = {
  allComponentsResource: SuspendableFetch<ComponentData[]>;
  platformType: string;
  projectId?: string;
  selectedComponents: DefaultItem[];
  sortOrder?: Sort;
};

const ComponentsData = ({
  platformType,
  allComponentsResource,
  selectedComponents,
  sortOrder,
}: ComponentsDataProps) => {
  const allComponents = allComponentsResource?.read();
  const sortedComponents = useMemo(() => {
    const arr = allComponents || [];
    if (sortOrder === Sort.asc) {
      arr.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (sortOrder === Sort.desc) {
      arr.sort((a, b) => b.name.localeCompare(a.name));
    }
    return arr;
  }, [sortOrder, allComponents]);

  const filteredComponents = useMemo(
    () =>
      sortedComponents.filter(
        (component) =>
          !selectedComponents ||
          !selectedComponents.length ||
          selectedComponents
            .map((selectedComponent) => selectedComponent.value)
            .includes(component.name)
      ),
    [sortedComponents, selectedComponents]
  );

  return (
    <>
      <div style={{ marginBottom: 32 }}>
        <StackedBarChart
          allComponents={sortedComponents}
          selectedItems={selectedComponents}
        />
      </div>
      <ComponentsAccordion
        components={filteredComponents}
        projectId={undefined}
        platformType={platformType}
      />
    </>
  );
};

export default ComponentsData;
