import React, { useContext, Suspense } from 'react';
import { useNavigate } from 'react-router';

import Accordion, { AccordionItem } from '@adsk/alloy-react-accordion';
import Tooltip from '@adsk/alloy-react-tooltip';
import Badge from '@adsk/alloy-react-badge';
import { LinkButton } from '@adsk/alloy-react-button';
import theme from '@adsk/alloy-react-theme';
import ProgressRing from '@adsk/alloy-react-progress-ring';

import InstancesPackageVersions from 'src/components/InstancesPackageVersions';
import { accordionItemStyle } from '../../pages/Projects/ProjectsAccordion';
import { ComponentData } from 'src/types';
import TrackerContext from '../../TrackerContext';
import HideContentsAccordionItem from '../HideContentsAccordionItem';

const ComponentsAccordion = ({
  components,
  projectId,
  platformType,
}: {
  components: ComponentData[];
  projectId: string | undefined;
  platformType?: string;
}) => {
  const navigate = useNavigate();
  const trackerContext = useContext(TrackerContext);

  return (
    <Accordion
      initialExpanded={[Number.MAX_SAFE_INTEGER]}
      renderItem={HideContentsAccordionItem}
    >
      {components.map((component) => (
        <AccordionItem
          key={component.name}
          style={accordionItemStyle}
          title={
            <div style={{ display: 'flex', alignItems: 'center', height: 36 }}>
              <Tooltip
                content={`Found ${component.instances.length} instances of ${component.name}`}
              >
                <Badge style={{ marginRight: 8 }}>
                  {component.instances.length}
                </Badge>
              </Tooltip>
              <LinkButton
                style={{ color: theme.colors.white }}
                onClick={() =>
                  navigate(
                    `${projectId ? `/projects/${projectId}` : ''}/components/${
                      component.name
                    }`
                  )
                }
              >
                {component.name}
              </LinkButton>
            </div>
          }
        >
          {trackerContext.data && (
            <Suspense fallback={<ProgressRing />}>
              <InstancesPackageVersions
                data={trackerContext.data.read()}
                component={component}
                platformType={platformType}
              />
            </Suspense>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ComponentsAccordion;
