import Badge from '@adsk/alloy-react-badge';
import React, { useMemo } from 'react';
import useComponentOverrideInstances from 'src/hooks/useComponentOverrideInstances';
import { groupInstances } from 'src/pages/Customizations/helpers';
import { ComponentData, SuspendableFetch } from 'src/types';

type CustomizationsTotalsProps = {
  allComponentsResource: SuspendableFetch<ComponentData[]>;
};

const CustomizationsTotals = ({
  allComponentsResource,
}: CustomizationsTotalsProps) => {
  const allComponents = allComponentsResource.read();

  const componentsOverrideInstances = useComponentOverrideInstances({
    allComponents,
  });
  const overridesByComponent = useMemo(() => {
    return groupInstances(componentsOverrideInstances, 'name');
  }, [componentsOverrideInstances]);

  const totalInstancesLabel = `${componentsOverrideInstances.length || 0}
  Instances`;
  const totalComponentsLabel = `${overridesByComponent.length || 0} Components`;

  return (
    <div>
      <Badge style={{ marginRight: 8 }}>{totalInstancesLabel}</Badge>
      <Badge variant={Badge.VARIANTS.OUTLINE} style={{ color: 'white' }}>
        {totalComponentsLabel}
      </Badge>
    </div>
  );
};

export default CustomizationsTotals;
