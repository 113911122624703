import React from 'react';

import { LogoGitHubIcon } from '@adsk/alloy-react-icon';

import { getGithubUrlForInstance } from 'src/utils';
import { Instance } from '../../types';
import { JSONProject } from '../../../../common/types';
import TrackerCard from '../TrackerCard/TrackerCard';

function InstanceCard({
  instance,
  org,
  project,
  ...props
}: {
  instance: Instance;
  org: string;
  project?: JSONProject;
}) {
  const location = project
    ? getGithubUrlForInstance(instance, project)
    : undefined;
  const filename = instance.location.file.substring(
    instance.location.file.lastIndexOf('/') + 1
  );

  const projectName = project ? `- ${project.name} ` : '';
  const lineNumber = instance.location.start.line;
  return (
    <TrackerCard
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {location && <LogoGitHubIcon size={20} style={{ marginRight: 10 }} />}
          {`${filename}#L${lineNumber} ${projectName}- ${org}`}
        </div>
      }
      link={location}
      {...props}
    >
      <ul style={{ fontFamily: 'monospace' }}>
        {Object.keys(instance.props).map((propKey) => (
          <li key={propKey}>
            <>
              <strong>{propKey}</strong>: {instance.props[propKey]}
            </>
          </li>
        ))}
      </ul>
    </TrackerCard>
  );
}

export default InstanceCard;
