import React from 'react';
import Components, { PLATFORM_TYPES } from './pages/Components/Components';
import Customizations from './pages/Customizations/Customizations';
import Projects from './pages/Projects/Projects';
import ErrorPage from './pages/Error';
import Project from './pages/Project/Project';
import Component from './pages/Component/Component';
import ProjectComponent from './pages/ProjectComponent/ProjectComponent';
import Landing from './pages/Landing/Landing';
import Frameworks from './pages/Frameworks/Frameworks';

export type AppRoute = {
  path: string;
  exact?: boolean;
  component: () => JSX.Element;
  title?: string;
};

const appRoutes = {
  error: {
    path: '/error',
    component: ErrorPage,
    title: 'Error',
  },
  landing: {
    path: '/*',
    component: Landing,
  },
  projects: {
    path: '/projects',
    component: Projects,
    title: 'Projects',
  },
  project: {
    path: '/projects/:projectId',
    component: Project,
  },
  projectComponent: {
    path: '/projects/:projectId/components/:componentId',
    component: ProjectComponent,
  },
  components: {
    path: '/components',
    component: () => <Components platformType={PLATFORM_TYPES.WEB} />,
    title: 'Web Components',
  },
  component: {
    path: '/components/:componentId',
    component: Component,
  },
  customizations: {
    path: '/customizations',
    component: Customizations,
    title: 'Web Customizations',
  },
  androidComponents: {
    path: '/android-components',
    component: () => <Components platformType={PLATFORM_TYPES.ANDROID} />,
    title: 'Android Components',
  },
  iosComponents: {
    path: '/ios-components',
    component: () => <Components platformType={PLATFORM_TYPES.IOS} />,
    title: 'IOS Components',
  },
  frameworks: {
    path: '/frameworks',
    component: Frameworks,
    title: 'Frameworks Services',
  },
};

// Makes appRoutes strongly typed and indexable
type AppRoutes = { [Property in keyof typeof appRoutes]: AppRoute };

export default appRoutes as AppRoutes;
