import React, { FC } from 'react';

import Badge from '@adsk/alloy-react-badge';
import Tooltip from '@adsk/alloy-react-tooltip';
import theme from '@adsk/alloy-react-theme';
import { SuspendableFetch } from 'src/types';
import { ProjectData } from '../../../../common/types';
import EmptyState from '@adsk/alloy-react-empty-state';

type ProjectVersionDiffCountsProps = {
  projectDataResource: SuspendableFetch<ProjectData>;
  projectId: string | undefined;
};

const TooltipContent: FC<{ version: string; count: number }> = ({
  version,
  count,
}) => {
  const message =
    count === 1
      ? `1 package is a ${version}`
      : `${count} packages are a ${version}`;
  return (
    <span>
      {message}
      <br />
      version behind the latest
    </span>
  );
};

const ProjectVersionDiffCounts: FC<ProjectVersionDiffCountsProps> = ({
  projectDataResource,
  projectId,
}) => {
  const project = projectDataResource
    .read()
    .repos.find((repo) => repo.name === projectId);

  if (!project) {
    return (
      <EmptyState
        title="Not found"
        style={{ backgroundColor: theme.colors.black }}
        description={`No matching project for: ${projectId}`}
      />
    );
  }

  return !project.versionsBehind ? null : (
    <div
      style={{
        display: 'flex',
        width: 100,
        justifyContent: 'space-between',
      }}
    >
      <Tooltip
        variant={Tooltip.VARIANTS.LIGHT}
        content={
          <TooltipContent
            version="major"
            count={project.versionsBehind.major}
          />
        }
      >
        <Badge
          color={
            project.versionsBehind.major
              ? Badge.COLORS.RED
              : theme.colors.charcoal800
          }
        >
          <span
            style={{
              color: project.versionsBehind.major
                ? 'inherit'
                : theme.colors.charcoal500,
            }}
          >
            {project.versionsBehind.major}
          </span>
        </Badge>
      </Tooltip>
      <Tooltip
        variant={Tooltip.VARIANTS.LIGHT}
        content={
          <TooltipContent
            version="minor"
            count={project.versionsBehind.minor}
          />
        }
      >
        <Badge
          color={
            project.versionsBehind.minor
              ? Badge.COLORS.YELLOW
              : theme.colors.charcoal800
          }
        >
          <span
            style={{
              color: project.versionsBehind.minor
                ? 'inherit'
                : theme.colors.charcoal500,
            }}
          >
            {project.versionsBehind.minor}
          </span>
        </Badge>
      </Tooltip>
      <Tooltip
        variant={Tooltip.VARIANTS.LIGHT}
        content={
          <TooltipContent
            version="patch"
            count={project.versionsBehind.patch}
          />
        }
      >
        <Badge
          color={
            project.versionsBehind.patch
              ? Badge.COLORS.GREEN
              : theme.colors.charcoal800
          }
        >
          <span
            style={{
              color: project.versionsBehind.patch
                ? 'inherit'
                : theme.colors.charcoal500,
            }}
          >
            {project.versionsBehind.patch}
          </span>
        </Badge>
      </Tooltip>
    </div>
  );
};

export default ProjectVersionDiffCounts;
