import React, { ReactNode } from 'react';
import theme from '@adsk/alloy-react-theme';

const Heading = ({ children }: { children?: ReactNode }) => (
  <span
    style={{
      ...theme.typography.heading1,
      marginTop: 0,
      color: theme.colors.white,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,
    }}
  >
    {children}
  </span>
);

export default Heading;
