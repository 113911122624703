import React, { Suspense, useContext } from 'react';
import { useParams } from 'react-router-dom';

import theme from '@adsk/alloy-react-theme';
import Divider from '@adsk/alloy-react-divider';

import ProjectBarChart from '../../components/ProjectBarChart';
import ProjectPackageVersions from 'src/components/ProjectPackageVersions';
import ProjectVersionDiffCounts from 'src/components/ProjectVersionDiffCounts';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import TrackerContext from 'src/TrackerContext';
import ProjectHeader from 'src/components/ProjectHeader';
import ProjectComponentsAccordion from 'src/components/ProjectComponentsAccordion';

function Project() {
  const { projectId } = useParams<{ projectId: string }>();

  const context = useContext(TrackerContext);

  return (
    <div
      style={{
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        color: theme.colors.white,
        ...theme.typography.bodyMedium,
      }}
    >
      {context.data && (
        <Suspense fallback={<ProgressRing />}>
          <ProjectHeader
            projectDataResource={context.data}
            projectId={projectId}
          />
        </Suspense>
      )}

      <div style={{ marginBottom: 8 }}>
        <span
          style={{
            color: theme.colors.adskBlue500,
            ...theme.typography.bodyMediumBold,
          }}
        >
          Package Versions
        </span>
        <Divider
          style={{
            background: theme.colors.charcoal500,
            margin: '8px 0 14px 0',
          }}
        />
        {context.data && (
          <>
            <div style={{ marginLeft: 24, marginBottom: 12 }}>
              <ProjectVersionDiffCounts
                projectDataResource={context.data}
                projectId={projectId}
              />
            </div>
            <ProjectPackageVersions
              projectDataResource={context.data}
              projectId={projectId}
            />
          </>
        )}
      </div>
      {context.allWebComponents && (
        <div style={{ marginTop: 24 }}>
          <Suspense fallback={<ProgressRing />}>
            <ProjectBarChart
              allComponentsResource={context.allWebComponents}
              projectId={projectId}
            />
          </Suspense>
        </div>
      )}
      <div style={{ display: 'flex', flexFlow: 'row wrap', marginTop: 10 }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            color: theme.colors.adskBlue500,
            ...theme.typography.bodyMediumBold,
          }}
        >
          <span>Components used in this project</span>
        </div>
        <Divider
          style={{
            background: theme.colors.charcoal500,
            margin: '8px 0 14px 0',
          }}
        />
        {context.allWebComponents && (
          <Suspense fallback={<ProgressRing />}>
            <ProjectComponentsAccordion
              allComponentsResource={context.allWebComponents}
              projectId={projectId}
            />
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default Project;
