import React, { FC, Suspense, useContext } from 'react';

import Badge from '@adsk/alloy-react-badge';
import Tooltip from '@adsk/alloy-react-tooltip';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import styled from 'styled-components';

import useComponentsInProject from 'src/hooks/useComponentsInProject';

import { getProjectComponentCount } from 'src/utils';

import PropTypes from 'prop-types';
import { ComponentData, SuspendableFetch } from 'src/types';
import TrackerContext from 'src/TrackerContext';

const StyledBadge = styled(Badge)`
  color: white;
  margin-right: 10px;
`;

const InternalComponentCountBadge: FC<{
  projectId?: string;
  allComponentsResource: SuspendableFetch<ComponentData[]> | undefined;
}> = ({ projectId, allComponentsResource }) => {
  const componentsInProject = useComponentsInProject({
    projectId,
    allComponents: allComponentsResource?.read(),
  });
  const count = getProjectComponentCount(componentsInProject);

  return (
    <Tooltip
      variant={Tooltip.VARIANTS.LIGHT}
      content={
        <span>
          This project uses {count} Alloy&nbsp;
          {count === 1 ? 'component' : 'components'}
        </span>
      }
    >
      <StyledBadge variant={Badge.VARIANTS.OUTLINE}>{count}</StyledBadge>
    </Tooltip>
  );
};

const ComponentCountBadge: FC<{
  projectName: string;
}> = ({ projectName }) => {
  const context = useContext(TrackerContext);

  if (!context.data || !context.latestScan) {
    return null;
  }

  return (
    <Suspense fallback={<ProgressRing />}>
      <InternalComponentCountBadge
        projectId={projectName}
        allComponentsResource={context.allWebComponents}
      />
    </Suspense>
  );
};

ComponentCountBadge.propTypes = {
  projectName: PropTypes.string.isRequired,
};

export default ComponentCountBadge;
