import { ComponentData } from 'src/types';

export default ({
  components,
  componentId,
}: {
  components?: ComponentData[];
  componentId?: string;
}) => {
  return components?.find(
    ({ name }) => name.toLowerCase() === componentId?.toLowerCase()
  );
};
