import React, { Suspense, useCallback, useState } from 'react';

import styled from 'styled-components';

import debounce from 'lodash.debounce';
import ProgressBar from '@adsk/alloy-react-progress-bar';
import theme from '@adsk/alloy-react-theme';
import { loadDependencyRepos, loadFrameworksRepoDependencies } from 'src/utils';
import { DefaultItem } from '@adsk/alloy-react-dropdown';
import SearchField from '@adsk/alloy-react-search-field';
import Toggle from '@adsk/alloy-react-toggle';

import RepoDependences from 'src/components/RepoDependencies';
import RepoPicker from 'src/components/RepoPicker';
import DependencyRepos from 'src/components/DependencyRepos';

const DependencySearchField = styled(SearchField)`
  width: 40%;
  input {
    background: ${(props) =>
      props.disabled ? theme.colors.charcoal700 : theme.colors.charcoal900};
    color: ${(props) =>
      props.disabled ? theme.colors.charcoal500 : theme.colors.white};
  }
`;

function Frameworks() {
  const [searchBy, setSearchBy] = useState<'repo' | 'dependency'>('dependency');
  const [selectedRepo, setSelectedRepo] = useState<DefaultItem | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearch = useCallback((value: string) => {
    console.log('searching for...', value);
    setSearchValue(value);
  }, []);

  const debouncedOnChange = debounce(handleSearch, 500);

  return (
    <div
      style={{
        color: theme.colors.white,
        fontFamily: 'monospace',
        marginTop: 16,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Suspense fallback={<>Loading Repo data...</>}>
          <RepoPicker
            selectedItem={selectedRepo}
            onSelectedItemChange={setSelectedRepo}
            disabled={searchBy !== 'repo'}
          />
        </Suspense>
        <Toggle
          checked={searchBy === 'dependency'}
          onChange={(val) => setSearchBy(val === true ? 'dependency' : 'repo')}
        />
        <DependencySearchField
          placeholder="Search by dependency..."
          defaultValue={searchValue}
          onChange={debouncedOnChange}
          disabled={searchBy !== 'dependency'}
        />
      </div>
      {searchBy === 'repo' && selectedRepo && (
        <Suspense fallback={<ProgressBar style={{ width: 600 }} />}>
          <RepoDependences
            resource={loadFrameworksRepoDependencies(selectedRepo.value)}
          />
        </Suspense>
      )}
      {searchBy === 'dependency' && (
        <Suspense fallback={<ProgressBar style={{ width: 600 }} />}>
          <DependencyRepos resource={loadDependencyRepos(searchValue)} />
        </Suspense>
      )}
    </div>
  );
}

export default Frameworks;
