import { formatDistance } from 'date-fns';
import semver from 'semver';
import bytes from 'bytes';
import groupBy from 'lodash.groupby';

import {
  ENTERPRISE_GITHUB_URL,
  GITHUB_URL,
  MAP_SEVERITY_TO_COLORS,
  VERSION_SEVERITY,
  ALLOY_REACT_PACKAGE,
} from 'src/consts';
import {
  Instance,
  ComponentData,
  FrameworksDependencyRepos,
  FrameworksOrgDependencies,
  FrameworksOrgRepos,
  UsageData,
  Scan,
  SuspendableFetch,
} from 'src/types';
import {
  JSONProject,
  ProjectData,
  ProjectPackage,
} from '../../../common/types';

import { INVALID_RESULT, WORKSPACE_LATEST } from '../../../common/consts';
import { LOG_PREFIX } from '../../../common/config';
import { sizes } from 'src/data/sizes';
import fetchData from './fetchData';
import fetchDataWithAuth from './fetchDataWithAuth';
import wrapPromise from './wrapPromise';

// for packages who are not using `@adsk/alloy` main
export const NO_VERSION = '0.0.0';

const safeCompare = (a = NO_VERSION, b = NO_VERSION) => {
  if (!semver.valid(a) || !semver.valid(b)) {
    return semver.compare(NO_VERSION, NO_VERSION);
  }

  return semver.compare(a, b);
};

export function load() {
  if (!process.env.REACT_APP_API_URL) {
    throw new Error('process.env.REACT_APP_API_URL not set');
  }
  return fetchDataWithAuth<ProjectData>(
    `${process.env.REACT_APP_API_URL}/repos`
  );
}

export function loadUsage() {
  if (!process.env.REACT_APP_USAGE_JSON_LOCATION) {
    throw new Error('process.env.REACT_APP_USAGE_JSON_LOCATION not set');
  }
  return fetchData<UsageData>(process.env.REACT_APP_USAGE_JSON_LOCATION);
}

export function loadLatestScan() {
  if (!process.env.REACT_APP_LATEST_SCAN_JSON_LOCATION) {
    throw new Error('process.env.REACT_APP_LATEST_SCAN_JSON_LOCATION not set');
  }
  return fetchData<Scan>(process.env.REACT_APP_LATEST_SCAN_JSON_LOCATION);
}

export function loadMobileUsage() {
  if (!process.env.REACT_APP_USAGE_MOBILE_JSON_LOCATION) {
    throw new Error('process.env.REACT_APP_USAGE_MOBILE_JSON_LOCATION not set');
  }
  return fetchDataWithAuth<UsageData>(
    process.env.REACT_APP_USAGE_MOBILE_JSON_LOCATION
  );
}

export function loadLatestAndroidScan() {
  if (!process.env.REACT_APP_LATEST_ANDROID_SCAN_JSON_LOCATION) {
    throw new Error(
      'process.env.REACT_APP_LATEST_ANDROID_SCAN_JSON_LOCATION not set'
    );
  }
  return fetchData<Scan>(
    process.env.REACT_APP_LATEST_ANDROID_SCAN_JSON_LOCATION
  );
}

export function loadLatestIosScan() {
  if (!process.env.REACT_APP_LATEST_IOS_SCAN_JSON_LOCATION) {
    throw new Error(
      'process.env.REACT_APP_LATEST_IOS_SCAN_JSON_LOCATION not set'
    );
  }
  return fetchData<Scan>(process.env.REACT_APP_LATEST_IOS_SCAN_JSON_LOCATION);
}

export function loadFrameworksRepos() {
  if (!process.env.REACT_APP_DEPENDENCY_ANALYZER_URL) {
    throw new Error('process.env.REACT_APP_DEPENDENCY_ANALYZER_URL not set');
  }
  return fetchData<FrameworksOrgRepos>(
    `${process.env.REACT_APP_DEPENDENCY_ANALYZER_URL}/v1/repos`
  );
}

export function loadFrameworksRepoDependencies(repo: string) {
  if (!process.env.REACT_APP_DEPENDENCY_ANALYZER_URL) {
    throw new Error('process.env.REACT_APP_DEPENDENCY_ANALYZER_URL not set');
  }
  return fetchData<FrameworksOrgDependencies>(
    `${process.env.REACT_APP_DEPENDENCY_ANALYZER_URL}/v1/dependencies/project/${repo}`
  );
}

export function loadDependencyRepos(dependency: string) {
  if (!process.env.REACT_APP_DEPENDENCY_ANALYZER_URL) {
    throw new Error('process.env.REACT_APP_DEPENDENCY_ANALYZER_URL not set');
  }
  return fetchData<FrameworksDependencyRepos>(
    `${process.env.REACT_APP_DEPENDENCY_ANALYZER_URL}/v1/dependents/${dependency}`
  );
}

export function getLastUpdated(lastUpdated = Date.now()) {
  return formatDistance(
    new Date(lastUpdated || Date.now()),
    new Date(Date.now())
  );
}

export function sortProjectsByVersion(
  repos: JSONProject[],
  direction: 'asc' | 'desc' = 'desc',
  packageToSortBy?: string
) {
  if (!packageToSortBy) {
    return repos;
  }

  return repos.sort((a, b) => {
    if (direction === 'desc') {
      return safeCompare(
        b.packages.find((pkg) => pkg.name === packageToSortBy)?.version,
        a.packages.find((pkg) => pkg.name === packageToSortBy)?.version
      );
    }

    return safeCompare(
      a.packages.find((pkg) => pkg.name === packageToSortBy)?.version,
      b.packages.find((pkg) => pkg.name === packageToSortBy)?.version
    );
  });
}

/**
 * 3 - unknown
 * 2 - green (up-to-date / some-patches-behind)
 * 1 - orange (some-minors-behind)
 * 0 - red (some-majors-behind)
 */
export const getVersionSeverity = (
  currentVersion?: string,
  latestVersion?: string
): keyof typeof MAP_SEVERITY_TO_COLORS => {
  const parsedCurrnet = semver.valid(currentVersion);
  const parsedLatestVersion = semver.valid(latestVersion);
  if (!parsedCurrnet || !parsedLatestVersion) {
    return 'unknown';
  }

  if (semver.eq(parsedCurrnet, parsedLatestVersion)) {
    return 'equal';
  }

  if (semver.gt(parsedCurrnet, parsedLatestVersion)) {
    return 'ahead';
  }

  const diff = semver.diff(parsedLatestVersion, parsedCurrnet);

  if (diff && Object.keys(MAP_SEVERITY_TO_COLORS).includes(diff)) {
    return diff as keyof typeof MAP_SEVERITY_TO_COLORS;
  }

  return 'unknown';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mostCommonInArray = (arr: any[]) =>
  (arr || []).reduce(
    (acc, el) => {
      acc.k[el] = acc.k[el] ? acc.k[el] + 1 : 1;
      acc.max = acc.max ? (acc.max < acc.k[el] ? el : acc.max) : el;
      return acc;
    },
    { k: {} }
  ).max;

const getPropType = (propValue: unknown) => {
  switch (propValue) {
    case null:
      // This is the case where a boolean is provided without value, like <SelectBox multiple />
      return 'boolean';
    case '(ArrowFunctionExpression)':
      return 'function';
    case '(CallExpression)':
      return 'node';
    case '(TSAsExpression)':
      return 'string';
    default:
      return typeof propValue;
  }
};

export const getAllPropsForComponent = (component: {
  components?: Record<
    string,
    {
      instances: Instance[];
    }
  >;
  instances?: Instance[];
}) => {
  if (!component.instances) {
    return [];
  }

  const allProps: { name: string; types: string[] }[] = [];

  component.instances.forEach((instance) => {
    Object.keys(instance.props).forEach((propKey) => {
      const propType = getPropType(instance.props[propKey]);
      if (allProps.some((prop) => prop.name === propKey)) {
        const prop = allProps.find((prop) => prop.name === propKey);
        if (prop) {
          prop.types.push(propType);
        }
        return;
      }

      allProps.push({ name: propKey, types: [propType] });
    });
  });

  allProps.sort((a, b) => {
    return b.types.length - a.types.length;
  });

  return allProps;
};

export const getGithubUrlForInstance = (
  instance: Instance,
  project: JSONProject,
  headBranch?: string
) => {
  const relativePath = instance.location.file.split(project.name)[1];
  const line = instance.location.start.line;
  const headPath = `blob/${headBranch ?? 'master'}`;

  return project?.url?.includes(GITHUB_URL)
    ? `${GITHUB_URL}/plangrid/${project.name}/${headPath}${relativePath}#L${line}`
    : `${ENTERPRISE_GITHUB_URL}/${project.org}/${project.name}/${headPath}${relativePath}#L${line}`;
};

export const getGithubUrlForHandle = (handle: string, repo?: JSONProject) => {
  const splitHandle = handle.replace('@', '').split('/');
  const isTeamHandle = splitHandle.length > 1;

  const path = isTeamHandle
    ? `orgs/${splitHandle[0]}/teams/${splitHandle[1]}`
    : splitHandle[0];

  return repo?.url?.includes(GITHUB_URL)
    ? `${GITHUB_URL}/${path}`
    : `${ENTERPRISE_GITHUB_URL}/${path}`;
};

export const getSlackUrlForChannel = (channel: string) => {
  const channelStripped = channel.replace('#', '');
  return `https://autodesk.slack.com/channels/${channelStripped}/`;
};

export const getJiraUrlForBoard = (code: string) => {
  const codeStripped = code.split(' ').join('').split(':')[1];
  return `https://jira.autodesk.com/projects/${codeStripped}/issues`;
};

export const getBundleSize = (url: string) => {
  const result = sizes.find((item) => item.url === url);
  if (!result?.size) return undefined;
  return bytes(result.size || 0, { unitSeparator: ' ' });
};

const isWebProdExpPackage = (pkg: ProjectPackage) => {
  return [
    '@adsk/acc-reference-picker',
    '@adsk/acs-sc-web-platform-api',
    '@adsk/acc-reference-picker-issues-plugin',
    '@adsk/alloy-project-page-layout',
    '@adsk/alloy-provider',
    '@adsk/alloy',
  ].includes(pkg.name);
};
const isDesignSystemPackage = (pkg: ProjectPackage) => {
  // this test over includes prod exp packages
  return /@adsk\/alloy-*/.test(pkg.name);
};

const getNumericDiff = (diffString: string | null) => {
  if (diffString === VERSION_SEVERITY.major) return 100;
  if (diffString === VERSION_SEVERITY.minor) return 10;
  if (diffString === VERSION_SEVERITY.patch) return 1;
  return 0;
};
const sortByOutdated = (a: ProjectPackage, b: ProjectPackage) => {
  if (a.version === WORKSPACE_LATEST || b.version === WORKSPACE_LATEST) {
    return 1;
  }

  if (a.version === INVALID_RESULT || b.latest === INVALID_RESULT) {
    return 1;
  }

  if (b.version === INVALID_RESULT || a.latest === INVALID_RESULT) {
    return -1;
  }

  let aDiff = 0;
  let bDiff = 0;

  // one library repo has its version set as 'file:packages/main' which breaks semver
  try {
    aDiff = a.latest ? getNumericDiff(semver.diff(a.version, a.latest)) : 0;
  } catch (ex) {
    console.log(LOG_PREFIX, a, ex);
  }

  try {
    bDiff = b.latest ? getNumericDiff(semver.diff(b.version, b.latest)) : 0;
  } catch (ex) {
    console.log(LOG_PREFIX, b, ex);
  }
  return bDiff - aDiff;
};

export const getProjectComponentCount = (projectComponents?: ComponentData[]) =>
  projectComponents?.reduce(
    (count, current) => count + current.instances.length,
    0
  );

export const getCategorizedPackages = (packages: ProjectPackage[]) => {
  const designSystemPackages: ProjectPackage[] = [];
  const webProdExpPackages: ProjectPackage[] = [];
  const otherPackages: ProjectPackage[] = [];
  packages.forEach((pkg) => {
    if (isWebProdExpPackage(pkg)) {
      webProdExpPackages.push(pkg);
    } else if (isDesignSystemPackage(pkg)) {
      designSystemPackages.push(pkg);
    } else {
      otherPackages.push(pkg);
    }
  });
  designSystemPackages.sort(sortByOutdated);
  webProdExpPackages.sort(sortByOutdated);
  otherPackages.sort(sortByOutdated);
  return { designSystemPackages, webProdExpPackages, otherPackages };
};

export const getPackageVersionsInRepo = (instances: Instance[]) => {
  const versions = Object.entries(
    groupBy(instances, (instance: Instance) =>
      instance.pkg ? `${instance.pkg.version} ${instance.pkg.name}` : undefined
    )
  ).filter(([key]) => key !== 'undefined');

  if (!versions.length) {
    return undefined;
  }

  let mainPkg: ProjectPackage | undefined;
  let globalImport = false;

  if (versions.length === 1) {
    const [pkg, instances] = versions[0];
    globalImport = pkg.split(' ')[1] === ALLOY_REACT_PACKAGE;
    mainPkg = instances[0].pkg;
  } else {
    const globalInstances = versions.find(
      (pkgData) => pkgData[0].split(' ')[1] === ALLOY_REACT_PACKAGE
    );
    if (globalInstances && versions.length === 2) {
      const pkgInstances = versions.find(
        (pkgData) => pkgData[0].split(' ')[1] !== ALLOY_REACT_PACKAGE
      );
      mainPkg = pkgInstances && pkgInstances[1][0].pkg;
    }
    globalImport = !!globalInstances;
  }

  return {
    mainPkg,
    globalImport,
  };
};

export const getUniquePackageNames = (data: ProjectData | undefined) => {
  const uniquePackageNamesSet = new Set<string>();

  data?.repos?.forEach((repos) => {
    repos.packages.forEach((pkg) => {
      uniquePackageNamesSet.add(pkg.name);
    });
  });

  const uniquePackageNamesArray = Array.from(uniquePackageNamesSet);

  const items = uniquePackageNamesArray.map((item: string) => ({
    label: item,
    value: item,
  }));

  return items;
};

export const parseAllComponents = ({
  projectData,
  scanData,
}: {
  projectData?: ProjectData;
  scanData?: Scan;
}): ComponentData[] => {
  if (
    !projectData?.repos ||
    Object.keys(projectData).length <= 0 ||
    !scanData ||
    Object.keys(scanData).length <= 0
  ) {
    return [];
  }
  const addProjectToInstance = (instance: Instance) => {
    const project = projectData.repos.find((repo) => {
      return instance.location.file.includes(repo.name);
    });
    const pkg = project?.packages.find(
      (p) => p.name === instance.importInfo.moduleName
    );
    return {
      ...instance,
      project,
      pkg,
    };
  };

  const componentsInProject = Object.keys(scanData).flatMap((componentName) => {
    const component = scanData[componentName];

    const toReturn = Object.keys(component.components || {}).map(
      (subComponentName) => {
        return {
          name: `${componentName}.${subComponentName}`,
          instances:
            component.components?.[subComponentName].instances.map(
              addProjectToInstance
            ) || [],
        };
      }
    );

    toReturn.push({
      name: componentName,
      instances: component.instances?.map(addProjectToInstance) || [],
    });

    return toReturn;
  });

  return componentsInProject.sort(
    (a, b) => b.instances?.length - a.instances?.length
  );
};

export const parseAllComponentsAsync = async ({
  projectDataResource,
  scanDataResource,
}: {
  projectDataResource: SuspendableFetch<ProjectData>;
  scanDataResource: SuspendableFetch<Scan>;
}): Promise<ComponentData[]> => {
  let projectDataPromise;
  let scanDataPromise;

  try {
    scanDataResource.read();
  } catch (scanPromise) {
    scanDataPromise = scanPromise;
  }

  const scanData = (await scanDataPromise) as Scan;

  try {
    projectDataResource.read();
  } catch (projPromise) {
    projectDataPromise = projPromise;
  }

  const projectData = (await projectDataPromise) as ProjectData;

  if (
    !projectData?.repos ||
    Object.keys(projectData).length <= 0 ||
    !scanData ||
    Object.keys(scanData).length <= 0
  ) {
    return [];
  }
  const addProjectToInstance = (instance: Instance) => {
    const project = projectData.repos.find((repo) => {
      return instance.location.file.includes(repo.name);
    });
    const pkg = project?.packages.find(
      (p) => p.name === instance.importInfo.moduleName
    );
    return {
      ...instance,
      project,
      pkg,
    };
  };

  const componentsInProject = Object.keys(scanData).flatMap((componentName) => {
    const component = scanData[componentName];

    const toReturn = Object.keys(component.components || {}).map(
      (subComponentName) => {
        return {
          name: `${componentName}.${subComponentName}`,
          instances:
            component.components?.[subComponentName].instances.map(
              addProjectToInstance
            ) || [],
        };
      }
    );

    toReturn.push({
      name: componentName,
      instances: component.instances?.map(addProjectToInstance) || [],
    });

    return toReturn;
  });

  return componentsInProject.sort(
    (a, b) => b.instances?.length - a.instances?.length
  );
};

export const getAllComponents = ({
  projectDataResource,
  scanDataResource,
}: {
  projectDataResource: SuspendableFetch<ProjectData>;
  scanDataResource: SuspendableFetch<Scan>;
}) => {
  const promise = parseAllComponentsAsync({
    projectDataResource,
    scanDataResource,
  }).then((res) => {
    return res;
  });

  return wrapPromise(promise);
};
