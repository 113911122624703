import React, { Suspense, useContext } from 'react';
import { useParams } from 'react-router-dom';

import theme from '@adsk/alloy-react-theme';
import TrackerContext from '../../TrackerContext';
import ComponentProps from 'src/components/ComponentProps';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import ComponentInstances from 'src/components/ComponentInstances';

function ProjectComponent() {
  const { projectId, componentId } =
    useParams<{ projectId: string; componentId: string }>();
  const context = useContext(TrackerContext);

  return (
    <>
      <p
        style={{
          margin: '0 0 24px 0',
          color: theme.colors.white,
          ...theme.typography.title,
        }}
      >
        {componentId}
      </p>
      <div style={{ color: theme.colors.white, fontFamily: 'monospace' }}>
        Props implemented:
        {context.allWebComponents && (
          <Suspense fallback={<ProgressRing />}>
            <ComponentProps
              allComponentsResource={context.allWebComponents}
              componentId={componentId}
            />
          </Suspense>
        )}
        {/* <ul>
          {allProps.map((prop) => (
            <li key={prop.name}>
              <strong>{prop.name}</strong>: {mostCommonInArray(prop.types)}
            </li>
          ))}
        </ul> */}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          color: theme.colors.charcoal900,
          ...theme.typography.bodyMedium,
        }}
      >
        {context.data && context.allWebComponents && (
          <ComponentInstances
            projectDataResource={context.data}
            allComponentsResource={context.allWebComponents}
            componentId={componentId}
            repoFilter={projectId}
            propFilter={[]}
          />
        )}
        {/* {component?.instances?.map((instance, index) => (
          <InstanceCard
            key={index}
            instance={instance}
            org={org}
            project={project}
          />
        ))} */}
      </div>
    </>
  );
}

export default ProjectComponent;
