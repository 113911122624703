import styled from 'styled-components';

const Page = styled.div`
  max-width: 1400px;
  padding: 40px 100px 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    padding: 40px;
  }
`;

export default Page;
