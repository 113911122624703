import React, { FC, memo, useCallback } from 'react';

import { DefaultItem } from '@adsk/alloy-react-dropdown';
import TrackerMultiAutocomplete from '../TrackerMultiAutocomplete';
import { UseMultipleSelectionStateChange } from 'downshift';
import { ComponentData, SuspendableFetch } from 'src/types';

type ComponentPickerProps = {
  allComponentsResource: SuspendableFetch<ComponentData[]>;
  selectedComponents: DefaultItem[];
  setSelectedComponents: (selectedComponents: DefaultItem[]) => void;
};

const ComponentPicker: FC<ComponentPickerProps> = ({
  allComponentsResource,
  selectedComponents,
  setSelectedComponents,
}) => {
  const allComponents = allComponentsResource?.read();
  const selectedItemsChanged = useCallback(
    (changes: UseMultipleSelectionStateChange<DefaultItem>) => {
      if (changes.selectedItems) {
        setSelectedComponents(changes.selectedItems);
      }
    },
    [setSelectedComponents]
  );

  return (
    <TrackerMultiAutocomplete
      placeholder={'Filter components by name...'}
      items={allComponents?.map((component) => ({
        label: component.name,
        value: component.name,
      }))}
      selectedItems={selectedComponents}
      onSelectedItemsChange={selectedItemsChanged}
    />
  );
};

export default memo(ComponentPicker);
