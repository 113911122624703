import React, { ComponentProps, useMemo } from 'react';
import { getUniquePackageNames } from 'src/utils';
import { ProjectData } from '../../../../common/types';
import TrackerMultiAutocomplete from 'src/components/TrackerMultiAutocomplete';

type ProjectsMultiAutocompleteProps = ComponentProps<
  typeof TrackerMultiAutocomplete
> & {
  data?: {
    read: () => ProjectData;
  };
};

const ProjectsMultiAutocomplete = ({
  data,
  ...props
}: ProjectsMultiAutocompleteProps) => {
  const allPackages = useMemo(
    () => getUniquePackageNames(data?.read()),
    [data]
  );

  return <TrackerMultiAutocomplete items={allPackages} {...props} />;
};

export default ProjectsMultiAutocomplete;
