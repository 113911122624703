import React, { ComponentProps } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@adsk/alloy-react-button';
import { LogoGitHubIcon } from '@adsk/alloy-react-icon';

const GithubLink = (
  props: ComponentProps<typeof IconButton> & { url: string }
) => (
  <IconButton
    size={20}
    renderIcon={(p) => <LogoGitHubIcon {...p} />}
    onClick={() => window.open(props.url, '_blank')}
    {...props}
  />
);

GithubLink.propTypes = {
  url: PropTypes.string.isRequired,
};

export default GithubLink;
