// eslint-disable-next-line
// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import Divider from '@adsk/alloy-react-divider';
import * as d3 from 'd3';
import theme from '@adsk/alloy-react-theme';
import useComponentsInProject from 'src/hooks/useComponentsInProject';
import { SuspendableFetch } from '../../types';

const defaultWidth = 1200;
const defaultHeight = 500;
const minRatio = 1.5;
const displayCount = 10;

const margin = { top: 50, right: 250, bottom: 50, left: 50 };
let width = defaultWidth - margin.left - margin.right;
let height = defaultHeight - margin.top - margin.bottom;

const defaultLegendOffset = 40;
const defaultRectWidth = 30;
// Q5 of Autodesk Color Spec
const colors = [
  '#CE6565',
  '#E0AF4B',
  '#E1E154',
  '#90D847',
  '#3BD23B',
  '#3BC580',
  '#3BBABA',
  '#689ED4',
  '#5178C8',
  '#9C6BCE',
  '#D46BC3',
  '#CE5C95',
];

type BarCartProps = {
  allComponentsResource: SuspendableFetch<ComponentData[]>;
  projectId: string | undefined;
};

const BarChart = ({ allComponentsResource, projectId }: BarCartProps) => {
  const svgRef = useRef();
  const allComponents = allComponentsResource?.read();
  const componentsInProject = useComponentsInProject({
    allComponents,
    projectId,
  });

  const setSize = () => {
    const newWidth = Math.min(window.innerWidth * 0.9, defaultWidth);
    const newHeight = Math.min(newWidth / minRatio, defaultHeight);
    width = newWidth - margin.left - margin.right;
    height = newHeight - margin.top - margin.bottom;
  };

  const findPackages = () => {
    const totalPackages = componentsInProject.map((comp) => {
      return {
        name: comp.name,
        value: comp.instances.length,
      };
    });

    totalPackages.sort((a, b) => b.value - a.value);
    return totalPackages;
  };

  const drawChart = (totalPackages) => {
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('class', 'BarChart')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const color = d3.scaleOrdinal().range(colors);

    const topPackages = totalPackages.slice(0, displayCount);

    // X Axis
    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(topPackages.map((el) => el.name))
      .padding(0.3);

    const xAxis = (g) =>
      g
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(x).tickSizeOuter(0).tickValues([]));
    svg.append('g').attr('class', 'axis').call(xAxis);

    // Y Axis
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(topPackages, (d) => d.value)])
      .range([height, 0])
      .nice();

    const yAxisTicks = y.ticks().filter(Number.isInteger);

    const yAxis = d3
      .axisLeft(y)
      .tickValues(yAxisTicks)
      .tickFormat(d3.format('d'));

    svg.append('g').attr('class', 'axis').call(yAxis);

    d3.selectAll('.axis')
      .style('stroke', theme.colors.charcoal500)
      .style('font-size', '12px');

    // Bar Chart bars
    svg
      .selectAll('mybar')
      .data(topPackages)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.name))
      .attr('y', (d) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d.value))
      .attr('fill', (d) => color(d.name));

    const drawLegend = () => {
      const legend = svg.append('g').attr('class', 'legend');

      let legendOffset = defaultLegendOffset;
      let rectWidth = defaultRectWidth;

      // calculates rough values to ensure legend responsiveness
      if (height + margin.bottom + margin.top < defaultHeight) {
        rectWidth = Math.min(
          Math.max((height - legendOffset) / displayCount, 18),
          defaultRectWidth
        );
        legendOffset = Math.min(
          Math.max(height / displayCount, 25),
          defaultLegendOffset
        );
      }

      legend
        .selectAll('roundedrect')
        .data(topPackages)
        .enter()
        .append('rect')
        .attr('rx', 6)
        .attr('ry', 6)
        .attr('x', width + rectWidth / 2)
        .attr('y', (d, i) => i * legendOffset - rectWidth / 2)
        .attr('width', rectWidth)
        .attr('height', rectWidth)
        .style('fill', (d) => color(d.name));

      legend
        .selectAll('labels')
        .data(topPackages)
        .enter()
        .append('text')
        .attr('x', width + legendOffset + rectWidth / 2)
        .attr('y', (d, i) => i * legendOffset)
        .style('fill', 'white')
        .text((d) => d.name)
        .attr('text-anchor', 'left')
        .style('font-size', '14px')
        .style('alignment-baseline', 'middle');

      legend
        .selectAll('numbers')
        .data(topPackages)
        .enter()
        .append('text')
        .attr('x', width + rectWidth)
        .attr('y', (d, i) => i * legendOffset)
        .style('fill', 'white')
        .text((d) => d.value)
        .attr('text-anchor', 'middle')
        .style('font-size', '14px')
        .attr('font-weight', '700')
        .style('alignment-baseline', 'central');
    };

    drawLegend();
  };

  const totalPackages = findPackages();

  const createBarChart = () => {
    setSize();
    if (totalPackages && totalPackages.length > 0) {
      d3.select('.BarChart').remove();
      drawChart(totalPackages);
    }
  };

  useEffect(() => {
    createBarChart();
  });

  let resizeTimer;
  window.onresize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      createBarChart();
    }, 100);
  };

  return (
    <div>
      <span
        style={{
          color: theme.colors.adskBlue500,
          textDecoration: 'none',
          ...theme.typography.bodyMediumBold,
        }}
      >
        Top 10 Alloy Components
      </span>
      <Divider
        style={{
          background: theme.colors.charcoal500,
          margin: '8px 0 14px 0',
        }}
      />
      <div ref={svgRef}></div>
    </div>
  );
};

export default BarChart;
