import React, { useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import theme from '@adsk/alloy-react-theme';
import Tabs, { Tab } from '@adsk/alloy-react-tabs';

import Heading from 'src/components/Layout/Heading';
import appRoutes, { AppRoute } from 'src/routes';

const renderTab = (route: AppRoute) => (
  <Tab tab={route.path} label={route.title}>
    <route.component />
  </Tab>
);

const tabStyle = {
  button: {
    color: theme.colors.white,
    '&:hover': { color: theme.colors.charcoal200 },
  },
};
const Landing = () => {
  const projects = useMatch(appRoutes.projects);
  const components = useMatch(appRoutes.components);
  const customizations = useMatch(appRoutes.customizations);
  const androidComponents = useMatch(appRoutes.androidComponents);
  const iosComponents = useMatch(appRoutes.iosComponents);
  const frameworks = useMatch(appRoutes.frameworks);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    projects?.pathname ||
      components?.pathname ||
      customizations?.pathname ||
      androidComponents?.pathname ||
      iosComponents?.pathname ||
      frameworks?.pathname
  );

  return (
    <>
      <Heading>Alloy Tracker</Heading>
      <Tabs
        style={tabStyle}
        active={activeTab}
        onChange={(tab) => {
          navigate(tab);
          setActiveTab(tab);
        }}
      >
        {renderTab(appRoutes.projects)}
        {renderTab(appRoutes.components)}
        {renderTab(appRoutes.customizations)}
        {renderTab(appRoutes.androidComponents)}
        {renderTab(appRoutes.iosComponents)}
        {renderTab(appRoutes.frameworks)}
      </Tabs>
    </>
  );
};

export default Landing;
