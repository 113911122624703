import theme from '@adsk/alloy-react-theme';
import A from '@adsk/alloy-react-a';
import React, { ReactNode } from 'react';
import { CSSObject } from 'styled-components';

function ExternalLink({
  href,
  children,
  style,
  ...props
}: {
  href: string;
  children: ReactNode;
  style?: CSSObject;
}) {
  return (
    <A
      href={href}
      target="_blank"
      style={{ color: theme.colors.charcoal500, ...style }}
      {...props}
    >
      {children}
    </A>
  );
}

export default ExternalLink;
