import { useMemo } from 'react';
import { ComponentData } from 'src/types';

export default function useComponentsInProject({
  projectId,
  allComponents,
}: {
  projectId?: string;
  allComponents?: ComponentData[];
}) {
  const comps = allComponents;
  if (!comps?.length) {
    return [];
  }

  return useMemo(
    () =>
      comps
        .map((component) => ({
          ...component,
          instances: component.instances.filter(
            (inst) => inst.project?.name === projectId
          ),
        }))
        .filter(({ instances }) => instances.length)
        .sort((a, b) => b.instances.length - a.instances.length),
    [comps, projectId]
  );
}
