export enum Sort {
  asc = 'asc',
  desc = 'desc',
}

export const SORT_FILTERS = {
  ALPHA: 'alphabetical',
  OUTDATED: 'outdated',
  COUNT: 'count',
  INSTANCES: 'instances',
  TYPESCRIPT: 'typescript',
} as const;

type SortFilters = typeof SORT_FILTERS;
export type SortFilter = SortFilters[keyof SortFilters];
