import groupBy from 'lodash.groupby';

import { OverrideInstance } from 'src/types';

export const GROUPING_PROJECT = 'project';
export const GROUPING_COMPONENT = 'component';

export const isCustomizationProp = (prop: string) =>
  prop.includes('render') || prop.includes('style') || prop.includes('css');

export const groupInstances = (instances: OverrideInstance[], prop: string) =>
  Object.entries(groupBy(instances, prop)).map(([key, value]) => ({
    key,
    instances: value as OverrideInstance[],
  }));
