import Badge from '@adsk/alloy-react-badge';
import { CrownIcon } from '@adsk/alloy-react-icon';
import Tooltip from '@adsk/alloy-react-tooltip';
import React, { CSSProperties, FC } from 'react';
import PropTypes from 'prop-types';

import { MAP_SEVERITY_TO_COLORS } from 'src/consts';
import { getVersionSeverity } from 'src/utils';
import { ProjectData, ProjectPackage } from '../../../../common/types';

type VersionBadgeProps =
  | {
      pkg?: ProjectPackage;
      data?: ProjectData;
      version?: never;
      severity?: never;
    }
  | {
      pkg?: never;
      data?: never;
      version?: string;
      severity: keyof typeof MAP_SEVERITY_TO_COLORS;
    };

const SIZES = ['small', 'medium'] as const;
type Size = typeof SIZES[number];

const VersionBadge: FC<
  VersionBadgeProps & { size?: Size; style?: CSSProperties }
> = ({ pkg, data, version, severity: severityProp, size = 'small', style }) => {
  const latestVersion = pkg?.name
    ? data?.packages?.[pkg.name].versions.latest
    : undefined;
  const severity =
    severityProp || getVersionSeverity(pkg?.version, latestVersion);

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      <Tooltip content={`latest: v${pkg?.latest}`}>
        <Badge
          style={{
            color: MAP_SEVERITY_TO_COLORS[severity],
            borderColor: MAP_SEVERITY_TO_COLORS[severity],
          }}
          size={size}
          variant="outline"
        >
          {version || pkg?.version}
        </Badge>{' '}
        {severity === 'ahead' && (
          <CrownIcon
            style={{ marginLeft: 8, color: MAP_SEVERITY_TO_COLORS[severity] }}
          />
        )}
      </Tooltip>
    </div>
  );
};

VersionBadge.propTypes = {
  pkg: PropTypes.any,
  data: PropTypes.any,
  version: PropTypes.string,
  severity: PropTypes.any,
  size: PropTypes.oneOf(SIZES),
  style: PropTypes.object,
};

export default VersionBadge;
