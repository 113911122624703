import styled from 'styled-components';
import theme from '@adsk/alloy-react-theme';

export const ScrollDiv = styled.div`
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 0;

  &::-webkit-scrollbar-track {
    background: ${theme.colors.charcoal600};
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.charcoal900};
    border-radius: 10px;
  }

  @media (min-width: 1200px) {
    overflow-x: hidden;
    padding-bottom: 15px;
  }
`;
