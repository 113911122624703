import React, { CSSProperties, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import theme from '@adsk/alloy-react-theme';
import Card, { CardHeader } from '@adsk/alloy-react-card';

import ExternalLink from '../ExternalLink';

const TrackerCard: FC<{
  title: ReactNode;
  link?: string;
  children?: ReactNode;
  headerStyle?: CSSProperties;
}> = ({ title, link, children, headerStyle = {} }) => {
  return (
    <Card
      renderHeader={({ style }) => (
        <CardHeader
          title={
            <div
              style={{ display: 'flex', alignItems: 'center', ...headerStyle }}
            >
              {link ? (
                link.includes('http') ? (
                  <ExternalLink href={link}>{title}</ExternalLink>
                ) : (
                  <Link
                    to={link}
                    style={{
                      textDecoration: 'none',
                      color: theme.colors.adskBlue500,
                    }}
                  >
                    {title as string}
                  </Link>
                )
              ) : (
                title
              )}
            </div>
          }
          style={style}
        />
      )}
      style={{
        flex: '1 1 30%',
        margin: '8px 8px 8px 0',
        color: theme.colors.charcoal500,
        backgroundColor: theme.colors.charcoal900,
        border: 'none',
        overflow: 'hidden',
        ...theme.typography.bodyMedium,
        hr: {
          backgroundColor: theme.colors.charcoal500,
        },
      }}
    >
      {children}
    </Card>
  );
};

TrackerCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  headerStyle: PropTypes.object,
};

export default TrackerCard;
