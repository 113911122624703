import React, { FC } from 'react';
import { FrameworksDependencyRepos, SuspendableFetch } from 'src/types';

type DependencyReposProps = {
  resource?: SuspendableFetch<FrameworksDependencyRepos>;
};

const NoResults = () => <>No Results</>;

const DependencyRepos: FC<DependencyReposProps> = ({ resource }) => {
  const data = resource?.read().data;

  if (!data) {
    return <NoResults />;
  }

  const results = Object.entries(data)
    .map(([githubOrg, repo]) =>
      Object.entries(repo).map(([repoName, repoData]) => {
        console.log({ githubOrg, repoName, repoData });
        return {
          githubOrg,
          repoName,
          repoData,
        };
      })
    )
    .flat();

  if (results.length === 0) {
    return <NoResults />;
  }

  console.log({ data, results });

  return (
    <>
      {results.map(({ githubOrg, repoName, repoData }) => (
        <>
          <h2>
            {githubOrg}: {repoName}
          </h2>
          <div style={{ display: 'flex' }}>
            Dependencies:
            <ul>
              {repoData.map((dep) => (
                <li key={dep.name}>
                  {dep.name}: {dep.versions}
                </li>
              ))}
            </ul>
          </div>
        </>
      ))}
    </>
  );
};

export default DependencyRepos;
