import theme from '@adsk/alloy-react-theme';

export const VERSION_SEVERITY = {
  major: 'major',
  minor: 'minor',
  patch: 'patch',
  equal: 'equal',
  ahead: 'ahead',
  unknown: 'unknown',
};
export const MAP_SEVERITY_TO_COLORS = {
  [VERSION_SEVERITY.major]: theme.colors.red500,
  [VERSION_SEVERITY.minor]: theme.colors.yellowOrange500,
  [VERSION_SEVERITY.patch]: theme.colors.green500,
  [VERSION_SEVERITY.equal]: theme.colors.green500,
  [VERSION_SEVERITY.ahead]: theme.colors.green500,
  [VERSION_SEVERITY.unknown]: theme.colors.charcoal500,
};

export const GITHUB_URL = 'https://github.com';
export const ENTERPRISE_GITHUB_URL = 'https://git.autodesk.com';

export const SLACK_CHANNEL = '#acs-alloy-eng';
export const GITHUB_TEAM = '@BIM360/acs-design-engineering';
export const ALLOY_REACT_PACKAGE = '@adsk/alloy-react';
