import React, { FC, memo, useMemo } from 'react';

import { DefaultItem } from '@adsk/alloy-react-dropdown';
import TrackerAutocomplete from '../TrackerAutocomplete';
import { loadFrameworksRepos } from 'src/utils';

type RepoPickerProps = {
  selectedItem: DefaultItem | null;
  onSelectedItemChange: (selectedItem: DefaultItem | null) => void;
  disabled?: boolean;
};

const reposPromise = loadFrameworksRepos();

const RepoPicker: FC<RepoPickerProps> = ({
  selectedItem,
  onSelectedItemChange,
  ...props
}) => {
  const repos = useMemo(() => reposPromise.read().data, []);

  const repoOptions = useMemo(
    () =>
      Object.entries(repos).map(([githubHost, repos]) => ({
        label: githubHost,
        items: repos.map((repo) => ({
          label: repo.name,
          value: repo.name,
        })),
      })),
    [repos]
  );

  return (
    <TrackerAutocomplete
      groupedItems={repoOptions}
      selectedItem={selectedItem}
      onSelectedItemChange={({ selectedItem }) =>
        onSelectedItemChange(selectedItem ?? null)
      }
      {...props}
    />
  );
};

export default memo(RepoPicker);
