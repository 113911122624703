// eslint-disable-next-line
// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import theme from '@adsk/alloy-react-theme';

// Set default width and height, calculate ratio
const default_width = 900;
const default_height = 500;
const default_ratio = default_width / default_height;

// Current (non-responsive) width and height are calcuated from the default, minus the margins
const margin = { top: 50, right: 100, bottom: 25, left: 50 };
let width = default_width - margin.left - margin.right;
let height = default_height - margin.top - margin.bottom;

function ChartSvg({
  usageData,
  highlightedComponents,
  setHighlightedComponents,
  selectedRepo,
}) {
  const svgRef = useRef();

  // Determine current size, which determines vars
  function set_vars() {
    const current_width = Math.min(window.innerWidth, 900);
    const current_height = window.innerHeight;
    const current_ratio = current_width / current_height;
    let h, w;
    // Check if height is limiting factor
    if (current_ratio > default_ratio) {
      h = current_height;
      w = h * default_ratio;
      // Else width is limiting
    } else {
      w = current_width;
      h = w / default_ratio;
    }

    // Set new width and height based on graph dimensions
    width = w - margin.left - margin.right;
    height = h - margin.top - margin.bottom;
  }

  set_vars();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function unHighlightAllPaths() {
    d3.selectAll('.pathLines')
      .transition()
      .duration(1000)
      .style('opacity', 1)
      .style('stroke-width', '1.5px')
      .attr('highlighted', 'false');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function highlightComponent(componentName, isLegend) {
    if (componentName === 'all') {
      unHighlightAllPaths();
      return;
    }

    if (isLegend) {
      const currentHighlight = document.querySelector('[highlighted=true]');
      if (currentHighlight) {
        const currentHighlightName = currentHighlight.id.substr(
          0,
          currentHighlight.id.indexOf('-')
        );
        if (componentName === currentHighlightName) {
          setHighlightedComponents([]);
          return;
        }
      }
      setHighlightedComponents([
        {
          value: componentName,
          label: componentName,
        },
      ]);
      return;
    }

    const thisId = componentName + '-line';

    d3.select('#' + thisId)
      .transition()
      .duration(1000)
      .style('opacity', 1)
      .style('stroke-width', '6px')
      .attr('highlighted', 'true');
  }

  function drawChart(selectedRepo) {
    // set the dimensions and margins of the graph

    // append the svg obgect to the body of the page, appends a 'group' element to 'svg', moves the 'group' element to the top left margin
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('class', 'mySvg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    //PARSE USAGE DATA
    const parseDate = d3.utcParse('%Y-%m-%d');

    //set selected repo
    const repo = selectedRepo ? selectedRepo.value : 'total';

    const packageSeries = usageData.series.map((s) => {
      return {
        name: s.name,
        values: s.values.map((v) => {
          return v[repo] ? v[repo] : 0;
        }),
      };
    });

    const data = {
      series: packageSeries,
      dates: usageData.dates.map((d) => parseDate(d)),
    };

    const x = d3.scaleUtc().domain(d3.extent(data.dates)).range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data.series, (d) => d3.max(d.values))])
      .nice()
      .range([height, 0]);

    const xAxis = (g) =>
      g
        .attr('transform', 'translate(0,' + height + ')')
        .call(
          d3
            .axisBottom(x)
            .tickSizeOuter(0)
            .ticks(d3.timeMonth)
            .tickFormat(d3.timeFormat('%b'))
        );
    svg.append('g').attr('class', 'axis').call(xAxis);

    const yAxisTicks = y.ticks().filter(Number.isInteger);

    const yAxis = d3
      .axisLeft(y)
      .tickValues(yAxisTicks)
      .tickFormat(d3.format('d'));

    svg
      .append('g')
      .attr('class', 'axis')
      .call(yAxis)
      .call((g) => g.select('.domain').remove());

    //color function pulls from array of colors stored in color.js
    const color = d3.scaleOrdinal().range(d3.schemeSet2);

    const line = d3
      .line()
      .x((d, i) => x(data.dates[i]))
      .y((d) => y(d));

    //path
    svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke-width', 1.5)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .selectAll('path')
      .data(data.series)
      .join('path')
      .attr('d', (d) => line(d.values))
      .attr('stroke', (d) => color(d.name))
      .attr('id', function (d) {
        return d.name + '-line';
      })
      .attr('class', 'pathLines');

    const dot = svg.append('g').attr('opacity', 0).attr('id', 'tooltip');
    dot.append('circle').attr('r', 2.5);

    dot
      .append('text')
      .attr('class', 'toolTipLineA')
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('y', -24)
      .attr('dy', '0em');

    dot
      .append('text')
      .attr('class', 'toolTipLineB')
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('y', -22)
      .attr('dy', '1em');

    const toolTipLine = svg
      .append('line')
      .attr('opacity', 0)
      .attr('id', 'toolTipLine')
      .attr('stroke', 'black');

    function removeTooltip() {
      if (d3.select('#tooltip').attr('opacity') === '1') {
        d3.select('#tooltip').attr('opacity', 0);
      }
      if (d3.select('#toolTipLine').attr('opacity') === '1') {
        d3.select('#toolTipLine').attr('opacity', 0);
      }
      d3.select('.toolTipLineA').text('');
      d3.select('.toolTipLineB').text('');
    }

    function drawTooltip(event) {
      const currentHighlight = document.querySelector('[highlighted=true]');
      if (!currentHighlight) return;

      event.preventDefault();
      const pointer = d3.pointer(event, this);
      const xm = x.invert(pointer[0]);
      const i = d3.bisectCenter(data.dates, xm);

      const currentHighlightName = currentHighlight.id.substr(
        0,
        currentHighlight.id.indexOf('-')
      );
      const s = data.series.filter((d) => d.name === currentHighlightName)[0];

      dot.attr('opacity', 1);
      dot.attr('transform', `translate(${x(data.dates[i])},${y(s.values[i])})`);
      const toolTipTextA = s.name + ': ' + s.values[i];
      const toolTipTextB = data.dates[i].toString().substr(4, 11);

      d3.select('.toolTipLineA')
        .text(toolTipTextA)
        .style('fill', theme.colors.charcoal500);
      d3.select('.toolTipLineB')
        .text(toolTipTextB)
        .style('fill', theme.colors.charcoal500);

      toolTipLine
        .attr('opacity', 1)
        .attr('x1', x(data.dates[i]))
        .attr('x2', x(data.dates[i]))
        .attr('y1', 0)
        .attr('y2', height)
        .style('fill', theme.colors.charcoal500);
    }

    //tipBox
    svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('opacity', 0)
      .style('z-index', -1)
      .style('pointer-events', 'all')
      .on('mousemove', drawTooltip)
      .on('mouseout', removeTooltip);

    d3.selectAll('.axis')
      .style('stroke', theme.colors.charcoal500)
      .style('font-size', '12px');
  }

  // Use a timer so the chart is not constantly redrawn while window is being resized.
  let resizeTimer;
  window.onresize = function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      d3.select('.mySvg').remove();
      set_vars();
      drawChart(selectedRepo);
    }, 100);
  };

  function redrawChart(selectedRepo) {
    d3.select('.mySvg').remove();
    drawChart(selectedRepo);
  }

  useEffect(() => {
    drawChart(selectedRepo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHighlightedComponents([]);
    unHighlightAllPaths();
    redrawChart(selectedRepo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRepo]);

  useEffect(() => {
    if (highlightedComponents?.length) {
      highlightedComponents.forEach((highlightedComponent) => {
        highlightComponent(highlightedComponent?.value);
      });
    } else {
      unHighlightAllPaths();
    }
  }, [highlightedComponents, highlightComponent, unHighlightAllPaths]);

  return <div ref={svgRef}></div>;
}

ChartSvg.propTypes = {
  usageData: PropTypes.object,
  selectedRepo: PropTypes.object,
  setHighlightedComponents: PropTypes.func,
  highlightedComponents: PropTypes.array,
};

export default ChartSvg;
