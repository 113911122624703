import wrapPromise from './wrapPromise';
import config from '../../../common/config';
import jwt from 'jsonwebtoken';
import { SuspendableFetch } from 'src/types';

const fetchWithAuth = async (url: string) => {
  const token = jwt.sign({ user: 'tracker' }, config.TRACKER_URL, {
    expiresIn: '1h',
  });

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const request = new Request(url, {
    method: 'GET',
    headers,
  });

  return fetch(request);
};
const fetchDataWithAuth = <T>(url: string): SuspendableFetch<T> => {
  const promise = fetchWithAuth(url).then((res) => res.json());

  return wrapPromise<T>(promise);
};

export default fetchDataWithAuth;
